import React, { useEffect, useState } from "react";
import axios from 'axios';
import { FloatingLabel, Form, Row, Col, Button } from "react-bootstrap";
import TextField from '@mui/material/TextField';

const EditLogindetails = ({ UsersId, getEditData, handleClose }) => {
    const [UserId, setUserId] = useState([])
    const [Password, setPassword] = useState([])
    const [OrganaizationId, setOrganaizationId] = useState([])

    const HandleEditLoginDetails = async (e) => {

        console.log("Edit Login Details")
        e.preventDefault();
        let data = { UserId, Password, OrganaizationId }
        getEditData(data)

    }


    ///////////////////////////////////
    //      EDIT THE CONTENT       ///
    /////////////////////////////////


    useEffect(() => {
        getdata();
    }, [])

    const getdata = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getloginprofile?UserId=${UsersId}`)
                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    setUserId(response.data.UserId)
                    setPassword(response.data.Password)
                    setOrganaizationId(response.data.OrganaizationId)

                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }


    return (
        <div style={{ width: "100%" }}>
            <Form onSubmit={HandleEditLoginDetails} style={{ width: "100%" }}>
                <Row className="mb-3">
                    <Col>
                        <TextField
                            disabled
                            id="standard-required"
                            label="User Id"
                            variant="standard"
                            value={UserId}
                            onChange={(e) => setUserId(e.target.value)}
                            style={{ width: '100%' }}
                        />

                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col>
                        <TextField
                            required
                            id="standard-required"
                            label="Password"
                            variant="standard"
                            value={Password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>

                </Row>

                {/* <div style={{ display: "flex", float: "right", width: "50%" }}>
                    <Button style={{ background: "#3871C2", width: "40%", marginRight: "20px", height: "50%", position: "relative", left: "50px" }} type="Submit">Update</Button>
                    <Button style={{ background: "#3871C2", width: "30%", height: "50%", position: "relative", left: "50px" }} onClick={handleClose}>close</Button>
                </div> */}
                <div style={{display:"flex", float:"right"}}>
                    <Button style={{ background: "#3871C2", width:"100px", marginRight:"20px", padding:"2px" }} type="Submit">Update</Button>
                    <Button style={{ background: "#3871C2", width:"100px" }} onClick={handleClose}>Cancel</Button>
                </div>
            </Form>
        </div>
    )
}

export default EditLogindetails
