import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import '../GenralComponent/table.css';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ModalBox from "../GenralComponent/ModelBox"
import EditLogindetails from "../GenralComponent/EditLogindetails";
import Avatar from '@mui/material/Avatar';


const Tables = ({ data, pageCount, handlePageClick, handleStatus, handleEditData }) => {
    console.log(data)
    const [show, setShow] = useState(false);
    const [UserId, setUserId] = useState([]);
    const handleClose = () => setShow(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const handleEditProfile = (UserId) => {
        setUserId(UserId)
        setShow(true)
    }

    const getEditData = (data) => {
        console.log(data)
        setShow(false);
        handleEditData(data)
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${name.split(' ')[0][0]}`,
        };
    }

    return (
        <div>
            <table style={{ textAlign: "center" }}>

                <tr style={{ backgroundColor: "#bbc0c2", height: "50px" }}>
                    <th style={{ width: "1%" }}></th>
                    <th style={{ width: "30%" }}>Organization Id</th>
                    <th>User Id</th>
                    <th>Password</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "2%" }}></th>
                </tr>


                {
                    data?.map((item, i) =>
                        <tr key={i}>

                            <td ><Avatar {...stringAvatar(item.UserId)} style={{marginLeft:"20px"}}/></td>

                            <td>{item.OrganaizationId}</td>
                            <td>{item.UserId}</td>
                            <td>{item.Password}</td>
                            <td>
                                <div style={{marginLeft: "25%" }}>

                                    {item.Status === "Active" ? <div style={{ backgroundColor: "#AED4A8", borderRadius: "10px", width: "80px", textAlign: "center", fontSize: "16px"}}> {item.Status} </div> :
                                        <div style={{ backgroundColor: "#E49696", borderRadius: "10px", width: "80px", textAlign: "center", fontSize: "16px" }}>{item.Status}</div>}
                                </div>
                            </td>
                            <td>

                                <div className="dropdown">
                                    <button className="dropdown-button" onClick={toggleDropdown}>
                                        <BiDotsVerticalRounded style={{ float: "right" }} />
                                    </button>

                                    {isDropdownOpen && (
                                        <div className="dropdown-content left" onClick={closeDropdown}>
                                            <a onClick={() => handleStatus(item.OrganaizationId, item.Status)}>{item.Status === "Active" ? <div> Suspend</div> : <div>Active</div>}</a>
                                            <a onClick={() => handleEditProfile(item.UserId)}>Edit Login</a>
                                        </div>
                                    )}
                                </div>

                            </td>

                        </tr>
                    )
                }

            </table>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
            />
            <ModalBox
                show={show}
                handleClose={handleClose}
                message={<EditLogindetails UsersId={UserId} getEditData={getEditData} handleClose={handleClose}/>}
                close={true}
                Style={{ marginTop: "100px" }}
            />
        </div>
    )
}

export default Tables
