import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import Login from "./Login/Login";
import SadDashBoard from './SuperAdmin/SadDashBoard';
import AdDashBoard from './Admin/AdDashBoard';
import OpDashBoard from './Operator/OpDashBoard';
import ReDashBoard from './Reviewer/ReDashBoard';
import PatientReport from './Reviewer/Reports/PatientReport';
import EditImages from './Reviewer/Reports/EditImages';
import { useState } from 'react';

function App() {
  const [Annotate,setAnnotate] = useState(false)
  const handleAnnotate = (data) => {
    setAnnotate(data)
  }
  let webLocation = window.location.href
  return (
    <div className="App">
      <HashRouter>
        <Routes>

          <Route exact path="/" element={<Login webLocation={webLocation}/>} />
          <Route path="/SuperAdminDashboard" element={<SadDashBoard/>} />
          <Route path="/AdminDashboard" element={<AdDashBoard/>} />
          <Route path="/OperatorDashboard" element={<OpDashBoard/>} />
          <Route path="/ReviewDashboard" element={<ReDashBoard/>} />
          <Route path="/ReviewDashboard/:ReportId" element={<PatientReport handleAnnotate={handleAnnotate} Annotate={Annotate} Role = {"Reviewer"}/>} />
          <Route path="/AdminDashboard/:ReportId" element={<PatientReport Annotate={Annotate} handleAnnotate={handleAnnotate} Role={"Admin"}/>} />
          <Route path="/ReviewDashboard/:ReportId/:Id" element={<EditImages/>} />

        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
