import React, { useState } from "react";
import { Col, Row } from 'react-bootstrap'
import { FiEdit2 } from 'react-icons/fi';
import ModalBox from "./ModelBox"
import EditProfile from "./EditProfile";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';



const DashBoard = ({ navigationbtn, tablelist, graph, UserId, Profile, Role }) => {

    const [Profileshow, setProfileShow] = useState(false);
    const handleClose = () => setProfileShow(false);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${name.split(' ')[0][0]}`,
        };
    }


    const LogOut = (e) => {
        e.preventDefault();
        const confirmSave = window.confirm('Do you want to Logout?');
        if (confirmSave) {
            localStorage.removeItem('isLoggedIn');
            localStorage.clear();
            window.location.replace('/');
        }
    };

    const handleProfile = () => {
        setProfileShow(true);
    }

    const HandelUpdateData = async (data, link) => {
        try {
            await axios
                .post(link, {
                    data
                })

                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    alert("Profile Updated Successfully");
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("login not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleUpdatedata = (data) => {
        console.log(data);
        const localdata = localStorage.getItem("Data")
        let UserData = JSON.parse(localdata)
        if (UserData.Role === "Admin") {
            HandelUpdateData(data, "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updateadminprofile")
        }
        else if (UserData.Role === "Operator") {
            HandelUpdateData(data, "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updateoperatorprofile")
        }
        else if (UserData.Role === "ReportReview") {
            HandelUpdateData(data, "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updatereviewprofile")
        }
        setProfileShow(false);
    }
    return (
        <div style={{ width: "100%", height: "100%" }}>

            <ModalBox
                show={Profileshow}
                handleClose={handleClose}
                message={<EditProfile data={handleUpdatedata} Profile={Profile} handleClose={handleClose} Role={Role}/>}
                // close={true}
                Style={{ marginTop: "100px" }}
            />
            <Row style={{ width: "100%", height: "100%" }}>
                <Col sm={2} style={{ backgroundColor: "white", height: "100%", borderRadius: "2%" }}>
                    <Row style={{ height: "22%" }}>
                        <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                            <div>
                                <Avatar {...stringAvatar(UserId)} style={{height:"55px", width:"55px"}}/>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "5px" }}>
                                {UserId} {UserId !== "admin_rmd" ? <FiEdit2 style={{ cursor: "pointer" }} onClick={() => { handleProfile() }} /> : null}

                            </div>
                        </div>
                    </Row>
                    <hr />

                    <Row style={{ height: "63%" }}>
                        {navigationbtn}
                    </Row>
                    <Row style={{ height: "15%", float: "left", margin: "5%" }}>
                        <div onClick={LogOut} style={{ cursor: "pointer" }}>
                            Sign-out
                        </div>

                    </Row>
                </Col>
                <Col sm={10}>
                    <Row style={{ height: "7%" }}>
                        <h3>welcome {UserId}</h3>
                    </Row>
                  

                    <Row style={{height:"90%"}}>
                        {tablelist}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default DashBoard
