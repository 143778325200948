import React, { useEffect, useState } from 'react'
import DashBoard from '../GenralComponent/DashBoard'
import axios from 'axios';
import NavigationBtn from './NavigationBtn';
import Tables from './Table';

const AdDashBoard = () => {
  const loginstatus = localStorage.getItem("isAdminLoggedIn")
  const data = localStorage.getItem("Data")
  let Admin = JSON.parse(data)
  const [loading, setLoading] = useState(true);
  const [Profile, setProfile] = useState()
  const [UserType, setUserType] = useState("Dashboard");

  useEffect(() => {
    setLoading(true);
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      await axios
        .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getprofile?UserId=${Admin.UserId}&Role=${Admin.Role}`)
        .then(response => {        
          let orgdata={OrganaizationId: response.data.OrganaizationId, OrganaizationName: response.data.OrganaizationName}
          localStorage.setItem('orgdata', JSON.stringify(orgdata))
          setProfile(response.data)
          
          setLoading(false);
          console.log('Status Code:', response.status);
          console.log('Data:',response.data);
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);
          } else {
            // Some other error occurred
            console.error('Error:', error.message);
          }
        })
    }
    catch (err) {
      console.error(err);
    }

  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleTable = (usertype) => {
    setUserType(usertype)
  }
 
  return (
    <div style={{
      width: "100%",
      display: "flexbox"
    }}>
      {loginstatus ? <div style={{ height: "100%" }}>
        <DashBoard
        Role= "Admin"
        UserId={Profile.UserId} 
        Profile = {Profile}
        navigationbtn={<NavigationBtn handleTable={handleTable}/>} 
        tablelist={<Tables UserType={UserType}/>} 
        />

      </div> : <div>
        {
          window.location.href = "/"
        }
      </div>}

    </div>
  )
}

export default AdDashBoard
