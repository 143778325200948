import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";

const ForgotPassword = ({ flag, handleClose }) => {
  const [UserId, setUserId] = useState([]);
  const [Otp, setOTP] = useState([]);
  const [Password, setPassword] = useState([])
  const [CPassword, setCPassword] = useState([])
  const [Flag, setFlag] = useState(flag);

  const HandelSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/ForgotPassword",
          { UserId }
        )
        .then((response) => {
          // Handle successful response
          console.log("Data:", response);
          alert("Please check your email for the OTP.");
          setFlag(2);
        });
    } catch (err) {
      console.error(err);
      alert("Your Email has not registered.")
    }
  };
  const SetNewPassword = async (e) => {
    e.preventDefault();
    if(Password === CPassword){
      try {
        await axios
          .post(
            "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/SetNewPassword",
            { UserId,Password,Otp}
          )
          .then((response) => {
            console.log("Data:", response);
            alert("Password got updated");
            handleClose()
            
          });
      } catch (err) {
        console.error(err);
        alert("Incorrect Password Please Try again.");
      }
    }
    else {
      alert("The password and confirm password do not match. Please re-enter them.")
    }
    
  };

  return (
    <div style={{ width: "100%" }}>
      {Flag === 1 ? (
        <Form gap={2} onSubmit={HandelSubmit}>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="User Id"
              className="mb-3"
              border="primary"
            >
              <Form.Control
                required
                type="text"
                placeholder="Please Enter UserId"
                style={{ borderColor: "#3D71B8", width: "100%" }}
                value={UserId}
                onChange={(e) => setUserId(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter UserId.
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>
          <div className="mt-2 mb-4">
            <Button
              style={{ background: "#3871C2", width: "100%" }}
              type="Submit"
            >
              Get OTP
            </Button>
          </div>
        </Form>
      ) : (
        <Form gap={2} onSubmit={SetNewPassword}>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="User Id"
              className="mb-3"
              border="primary"
            >
              <Form.Control
                required
                type="text"
                placeholder="Please Enter UserId"
                style={{ borderColor: "#3D71B8", width: "100%" }}
                value={UserId}
                onChange={(e) => setUserId(e.target.value)}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                Please Enter UserId.
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>

          <div>
            <FloatingLabel controlId="floatingPassword" label="Enter OTP">
              <Form.Control
                required
                // type="password"
                placeholder="Please Enter the OTP"
                className="mb-3"
                style={{ borderColor: "#3D71B8" }}
                value={Otp}
                onChange={(e) => setOTP(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter OTP.
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>

          <div>
            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control
                required
                type="password"
                placeholder="Password"
                className="mb-3"
                style={{ borderColor: "#3D71B8" }}
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Password.
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>

          <div>
            <FloatingLabel controlId="floatingPassword" label="Confirm Password">
              <Form.Control
                required
                type="password"
                placeholder="Confirm Password"
                className="mb-3"
                style={{ borderColor: "#3D71B8" }}
                value={CPassword}
                onChange={(e) => setCPassword(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Password.
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>
          <div className="mt-2 mb-4">
            <Button
              style={{ background: "#3871C2", width: "100%" }}
              type="Submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ForgotPassword;
