import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Avatar from '@mui/material/Avatar';

const ReportCountDB = () => {
    const [currentPage, setcurrentPage] = useState(1)
    let limit = 5

    useEffect(() => {
        getAdminList();
    }, [currentPage]);

    const getAdminList = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getadminlist?page=${currentPage}&page_size=${limit}`)
                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    // setTableData(response.data.data);
                    // setCount(response.data.count)
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${name.split(' ')[0][0]}`,
        };
    }

    return (
        <div>
            <table style={{ textAlign: "center" }}>

                <tr style={{ backgroundColor: "#bbc0c2", height: "50px" }}>
                    <th style={{ width: "1%" }}></th>
                    <th style={{ width: "30%" }}>Organization Id</th>
                    <th>User Id</th>
                    <th>Total</th>
                    <th>Pending</th>
                    <th>Complete</th>
                </tr>

                <tr>
                    <td>
                        <Avatar {...stringAvatar("Abc")} style={{ marginLeft: "20px" }} />                    </td>
                </tr>
            </table>
        </div>
    )
}

export default ReportCountDB
