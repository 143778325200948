import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPenFancy, FaExpand, FaSave } from "react-icons/fa";
import { MdOutlineDoneAll } from "react-icons/md";
import { BsCircle } from "react-icons/bs";
import { LuSpline } from "react-icons/lu";
import { AiOutlineClear } from "react-icons/ai";
import { Col, Row, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Slider from "@mui/material/Slider";

const ImageEditor = () => {
  const { ReportId } = useParams();
  const navigate = useNavigate();
  const [Payload, setPayload] = useState([]);
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [context, setContext] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [inputImg, setInputImg] = useState(new Image());
  const [currentTool, setCurrentTool] = useState("line");
  const [shape, setShape] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [penColor, setPenColor] = useState("#FF0000");
  const [penSize, setPenSize] = useState(2);
  const [contrast, setContrast] = useState(100); // Initial contrast value (percentage)
  const [brightness, setBrightness] = useState(100);
  const [SelectedImages, setSelectedImages] = useState([]);
  const [TempImages, setTempImages] = useState([]);

  const idb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.oIndexedDB || window.shimIndexedDB;


  const getDBdata = () => {
    return new Promise((resolve, reject) => {
      const dbPromise = idb.open("test-db", 2);

      dbPromise.onsuccess = () => {
        const db = dbPromise.result;
        const tx = db.transaction("userData", "readonly");
        const userData = tx.objectStore("userData");
        const user = userData.getAll();

        user.onsuccess = (query) => {
          resolve(query.srcElement.result);
        };

        user.onerror = (event) => {
          reject("Error occurred: " + event);
        };

        tx.oncomplete = () => {
          db.close();
        };
      };

      dbPromise.onerror = (event) => {
        reject("Error opening database: " + event.target.error);
      };
    });
  }

  
  const getImageList = async (flag) => {
    try {
      let PatientData = await getDBdata();
      setPayload(PatientData[0].data.Payload);

      if (!flag) {
        setSelectedImages(PatientData[0].data.Payload)
        setTempImages(PatientData[0].data.Payload[0])
        return PatientData[0].data.Payload[0]
      }
    } catch (error) {
      console.error("Error:", error);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let img = await getImageList();
        let imgdata = await img;
        canva(imgdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  const canva = (imgdata) => {
    const canvas = document.getElementById("canvas");
    inputImg.src = `data:image/jpeg;base64,${imgdata.ImageData}`;

    if (canvas) {
      drawImageOnCanvas(inputImg);
    }
  };

  const drawImageOnCanvas = (img) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    setContext(ctx);
    setCanvas(canvas);
    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };

  const startDrawing = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    setDrawing(true);
    setShape({
      type: currentTool,
      color: penColor,
      size: penSize,
      start: { x: offsetX, y: offsetY },
      end: { x: offsetX, y: offsetY },
      points: [],
    });
  };

  const draw = (e) => {
    if (!drawing) return;

    const { offsetX, offsetY } = e.nativeEvent;
    const updatedShape = {
      ...shape,
      end: { x: offsetX, y: offsetY },
      points: [...shape.points, { x: offsetX, y: offsetY }],
    };
    setShape(updatedShape);

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.drawImage(inputImg, 0, 0, canvas.width, canvas.height);

    shapes.forEach((shp) => {
      drawShape(shp);
    });

    drawShape(updatedShape);
  };

  const endDrawing = () => {
    if (drawing) {
      setDrawing(false);
      setShapes([...shapes, shape]);
      setShape(null);
    }
  };

  const drawShape = (shp) => {
    if (!shp || !shp.type) {
      return;
    }

    const { type, color, size, start, end, points } = shp;

    context.beginPath();
    context.strokeStyle = color;
    context.lineWidth = size;

    if (type === "pen") {
      context.lineCap = "round";
      context.lineJoin = "round";
      if (points && points.length > 0) {
        points.forEach((point, index) => {
          if (index === 0) {
            context.moveTo(point.x, point.y);
          } else {
            context.lineTo(point.x, point.y);
          }
        });
        context.stroke();
      }
    } else if (type === "line") {
      if (start && end) {
        context.moveTo(start.x, start.y);
        context.lineTo(end.x, end.y);
        context.stroke();
      }
    } else if (type === "circle") {
      if (start && end) {
        const radius = Math.sqrt(
          Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
        );
        context.arc(start.x, start.y, radius, 0, 2 * Math.PI);
        context.stroke();
      }
    } else if (type === "rectangle") {
      if (start && end) {
        const width = end.x - start.x;
        const height = end.y - start.y;
        context.rect(start.x, start.y, width, height);
        context.stroke();
      }
    }
  };

  const handleClearCanvas = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    setShapes([]);
    context.drawImage(inputImg, 0, 0, canvas.width, canvas.height);
  };

  const clearCanvas = () => {
    setShapes([]);
    context.drawImage(inputImg, 0, 0, canvas.width, canvas.height);
  };
  const handleRGBSeparation = (channel) => {
    clearCanvas();
    separateChannel(channel);
  };
  const separateChannel = (targetChannel) => {
    if (canvas && context) {
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        if (targetChannel === "red") {
          data[i + 1] = data[i + 0];
          data[i + 2] = data[i + 0];
        } else if (targetChannel === "green") {
          data[i] = data[i + 1];
          data[i + 2] = data[i + 1];
        } else if (targetChannel === "blue") {
          data[i] = data[i + 2];
          data[i + 1] = data[i + 2];
        }
      }
      context.putImageData(imageData, 0, 0);
    }
  };

  const addDBData = (data) => {

    const dbPromise = idb.open("test-db", 2);

    dbPromise.onsuccess = (event) => {
      const db = event.target.result;
      const tx = db.transaction("userData", "readwrite");
      const userData = tx.objectStore("userData");

      const user = userData.put({
        ReportId: 1,
        data,
      });

      user.onsuccess = () => {
        tx.oncomplete = () => {
          db.close();
        };
        console.log("db added successfully");
      };

      user.onerror = (event) => {
        console.log(event);
        alert("User not added");
      };
    };

    dbPromise.onerror = (event) => {
      console.error("Error opening database:", event.target.error);
    };
  };


  const handleSave = async () => {
    try {
      const canvas = document.getElementById("canvas"); // Replace with your actual canvas ID
      let PatientData = await getDBdata();
      const existingDataString = PatientData[0].data;

      if (existingDataString) {
        const PatientData = existingDataString
        const existingData = PatientData.Payload;

        const indexToUpdate = existingData.findIndex(
          (item) => item.Id === TempImages.Id
        );
        const ImageindexToUpdate = SelectedImages.findIndex(
          (item) => item.Id === TempImages.Id
        );


        if (indexToUpdate !== -1 && ImageindexToUpdate !== -1) {
          canvas.toBlob((blob) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              const dataURL = reader.result.split(",")[1];

              existingData[indexToUpdate].ImageData = dataURL;
              SelectedImages[ImageindexToUpdate].ImageData = dataURL;
              PatientData.Payload = existingData;
              addDBData(PatientData)
              setShapes([]);
              getImageList(1);
            };

            reader.readAsDataURL(blob);
          }, "image/jpeg");
        } else {
          alert("Please Select The Edited Image")
          console.log('Object with Id "image11" not found in local storage.');
        }

      } else {
        console.log("No data found in local storage.");
      }
    } catch (error) {
      console.error("Error:", error);
    }

  };

  const handleSaveAndClose = async () => {
    try {
      let PatientData = await getDBdata();
      const existingDataString = PatientData[0].data;

      if (existingDataString) {
        const PatientData = existingDataString;
        PatientData.Payload = SelectedImages
        addDBData(PatientData)
        navigate(`/ReviewDashboard/${ReportId}`);

      } else {
        console.log("No data found in local storage.");
      }
      alert("Saved");

    } catch (error) {
      console.error("Error:", error);
    }



  };

  const handleImageClick = (image) => {
    getImageList(1);
    setTempImages(image);
    setBrightness(100);
    applyBrightness(100);
    setContrast(100)
    applyContrast(100);
    canva(image);
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
    applyContrast(newValue);
  };

  const applyContrast = (value) => {
    const contrastFactor = value / 100;
    context.filter = `contrast(${contrastFactor})`;
    clearCanvas();
  };

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
    applyBrightness(newValue);
  };

  const applyBrightness = (value) => {
    const brightnessFactor = value / 100;
    context.filter = `brightness(${brightnessFactor}) contrast(${contrast / 100
      })`;
    clearCanvas();
  };

  const handleCheckboxChange = (Image) => {
    const maxSelection = Payload.length;
    if (SelectedImages.find((selected) => selected.Id === Image.Id)) {
      // If the Image is already selected, remove it from the array
      setSelectedImages(
        SelectedImages.filter((selected) => selected.Id !== Image.Id)
      );
      console.log(Image)
    } else if (SelectedImages.length < maxSelection) {
      // If the maximum limit is not reached, add the Image to the array
      setSelectedImages([...SelectedImages, Image]);
      console.log(Image)
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ height: "100%", width: "100%" }}>
        <Col sm={8}>
          <center>
            Annotate Images{" "}
            <lable style={{ color: "orange" }}>
              !Please Don't Refresh This Page & Save After Changes
            </lable>
          </center>
          <canvas
            width={900}
            height={650}
            style={{ margin: "3%", marginLeft: "3%" }}
            id="canvas"
            ref={canvasRef}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={endDrawing}
            onMouseOut={endDrawing}
          ></canvas>
        </Col>
        <Col sm={1}>
          {Payload?.map((item) => (
            <div style={{ position: "relative" }}>
              <input
                type="checkbox"
                checked={SelectedImages.some(
                  (selected) => selected.Id === item.Id
                )}
                onChange={() => handleCheckboxChange(item)}
                style={{ position: "absolute", left: "0" }}
              />
              <div
                key={item.Id}
                style={{
                  height: "100px",
                  width: "100%",
                  marginTop: "15px",
                  opacity: SelectedImages.some(
                    (selected) => selected.Id === item.Id
                  )
                    ? "1"
                    : "0.5", // Set opacity based on checkbox state
                  pointerEvents: SelectedImages.some(
                    (selected) => selected.Id === item.Id
                  )
                    ? "auto"
                    : "none", // Enable or disable pointer events based on checkbox state
                }}
                onClick={() => handleImageClick(item)}
              >
                {" "}
                <Tooltip
                  title="! Save After Edit"
                  placement="left"
                >

                  <img
                    alt=""
                    src={`data:image/jpeg;base64,${item.ImageData}`}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Tooltip>
              </div>
            </div>

          ))}
        </Col>
        <Col
          sm={3}
          style={{
            backgroundColor: "white",
            height: "100%",
            textAlign: "center",
            overflow: "scroll",
            position: "relative"
          }}
        >
          <Row style={{ height: "40%" }}>
            <h4 style={{ textAlign: "center" }}>Channels</h4>
            <hr />

            <Col>
              <button
                onClick={() => handleRGBSeparation("red")}
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                R
              </button>
            </Col>

            <Col>
              <button
                onClick={() => handleRGBSeparation("green")}
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                G
              </button>
            </Col>

            <Col>
              <button
                onClick={() => handleRGBSeparation("blue")}
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "blue",
                  color: "white",
                }}
              >
                B
              </button>
            </Col>

            <Col>
              <button
                onClick={() => clearCanvas()}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  backgroundImage:
                    "conic-gradient(red 0deg,red 120deg, green 120deg,green 240deg,blue 60deg)",
                }}
              ></button>
            </Col>

            <List>
              <ListItemButton>
                <ListItemText>
                  <FormControl fullWidth>
                    <InputLabel id="contrast-slider-label">Contrast</InputLabel>
                    <Slider
                      value={contrast}
                      onChange={handleContrastChange}
                      aria-labelledby="contrast-slider-label"
                      valueLabelDisplay="auto"
                      min={0}
                      max={200} // Adjust the max value based on your preference
                    />
                  </FormControl>
                </ListItemText>
              </ListItemButton>
            </List>

            <List>
              <ListItemButton>
                <ListItemText>
                  <FormControl fullWidth>
                    <InputLabel id="brightness-slider-label">
                      Brightness
                    </InputLabel>
                    <Slider
                      value={brightness}
                      onChange={handleBrightnessChange}
                      aria-labelledby="brightness-slider-label"
                      valueLabelDisplay="auto"
                      defaultValue={100}
                      min={0}
                      max={200} // Adjust the max value based on your preference
                    />
                  </FormControl>
                </ListItemText>
              </ListItemButton>
            </List>
          </Row>

          <Row style={{ height: "35%", marginBottom: "20px" }}>
            <h4 style={{ textAlign: "center", marginTop: "20px" }}>Tools</h4>
            <hr />

            <Row style={{ marginBottom: "10px", height: "20%", width: "100%" }}>
              <Col style={{
                borderRadius: "10px",
                height: "100%",
                backgroundColor: "#CDE5FF",
                marginRight: "10px"
              }}>

                <Form.Label style={{ height: "100%", width: "60%", float: "left", marginTop: "10px" }}>Color</Form.Label>

                <Form.Control
                  type="color"
                  value={penColor}
                  onChange={(e) => setPenColor(e.target.value)}
                  style={{
                    marginLeft: "10px",
                    width: "30%",
                    float: "left",
                    height: "100%"
                  }}
                />
              </Col>
              <Col style={{
                borderRadius: "10px",
                height: "100%",
                backgroundColor: "#CDE5FF"
              }}>

                <Form.Label style={{ height: "100%", width: "50%", float: "left", marginTop: "10px" }}>Size</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="10"
                  value={penSize}
                  onChange={(e) => setPenSize(e.target.value)}
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                    height: "100%",
                    float: "left"
                  }}
                />
              </Col>

            </Row>

            <Row style={{ marginBottom: "10px", height: "20%", width: "100%" }}>
              <Col xs={6}>

                <button
                  onClick={() => setCurrentTool("pen")}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <FaPenFancy /> Pen
                </button>
              </Col>

              <Col xs={6}>
                <button
                  onClick={() => setCurrentTool("circle")}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <BsCircle />
                  Circle
                </button>
              </Col>
            </Row>

            <Row style={{ marginBottom: "10px", height: "20%", width: "100%" }}>
              <Col xs={6}>
                <button
                  onClick={() => setCurrentTool("rectangle")}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <FaExpand />
                  Square
                </button>
              </Col>
              <Col xs={6}>
                <button
                  onClick={() => setCurrentTool("line")}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <LuSpline />
                  Line
                </button>
              </Col>
            </Row>

            <Row style={{ marginBottom: "10px", height: "20%", width: "100%" }}>
              <Col>
                <button
                  onClick={() => handleClearCanvas()}
                  style={{
                    borderRadius: "10px",
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <AiOutlineClear />
                  Clear
                </button>
              </Col>
            </Row>
          </Row>

          <Row style={{ height: "20%" }}>

            <Row style={{ marginTop: "50px", position: "relative" }}>
              <Col>
                <button style={{ borderRadius: "10px" }} onClick={handleSave}>
                  <FaSave /> Save
                </button>
              </Col>
              <Col>
                <button style={{ borderRadius: "10px" }} onClick={handleSaveAndClose}>
                  <MdOutlineDoneAll /> Done
                </button>
              </Col>
            </Row>
          </Row>



        </Col>
      </Row>
    </div>
  );
};

export default ImageEditor;
