import React, { useEffect, useState } from 'react'
import DashBoard from '../GenralComponent/DashBoard'
import axios from 'axios';
import ReportDashBoard from './ReportDashBoard';

const ReDashBoard = () => {
  const loginstatus = localStorage.getItem("isReviewLoggedIn")
  const data = localStorage.getItem("Data")
  let Review = JSON.parse(data)
  const [loading, setLoading] = useState(true);
  const [Profile, setProfile] = useState()
  const [ReviewId, setReviewId] = useState("")
  let limit = 5

  useEffect(() => {
    fetchProfile();
  }, []);



  const fetchProfile = async () => {
    try {
      await axios
        .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getprofile?UserId=${Review.UserId}&Role=${Review.Role}`
        )
        .then(response => {
          let orgdata = { OrganaizationId: response.data.OrganaizationId, OrganaizationName: response.data.OrganaizationName, ReviewId:response.data.ReviewId, ReviewerName: response.data.UserName, ReviewerSignature: response.data.Image}
          localStorage.setItem('orgdata', JSON.stringify(orgdata))
          setReviewId(response.data.ReviewId)
          setProfile(response.data)
          setLoading(false);
          console.log('Status Code:', response.status);
          console.log('Data:', response.data);
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);
          } else {
            // Some other error occurred
            console.error('Error:', error.message);
          }
        })
    }
    catch (err) {
      console.error(err);
    }

  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
      width: "100%",
      display: "flexbox"
    }}>
      {loginstatus ? <div style={{ height: "100%" }}>
        <DashBoard
          UserId={Profile.UserId}
          Profile={Profile}
          Role = "ReportReview"
          // navigationbtn={<NavigationBtn Pending={handlePending} Complete={handleComplete} />}
          // tablelist={StatusControl === "Pending" ? <RRPendingTable OrganaizationId={OrganaizationId} ReviewId={ReviewId} limit={limit} UserId={Profile.UserId}/> : <RRCompleteTable OrganaizationId={OrganaizationId} ReviewId={ReviewId} limit={limit} UserId={Profile.UserId}/>}
          tablelist={<ReportDashBoard ReviewId={ReviewId} UserId={Profile.UserId}/>}
        />
      </div> : <div>
        {
          window.location.href = "/"
        }
      </div>}

    </div>
  )
}

export default ReDashBoard
