import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddUsers = ({ Role, data, handleClose, header, ScreeningCrenterData }) => {
    const org = localStorage.getItem("orgdata")
    let orgdata = JSON.parse(org)
    const [UserId, setUserId] = useState([])
    const [ReviewNameList, setReviewNameList] = useState()
    const [ScreeningCenterList, setScreeningCenterList] = useState([])
    const [ScreeningCenter, setScreeningCenter] = useState([])
    const [Password, setPassword] = useState([])
    const [OrganaizationName, setOrganaizationName] = useState(orgdata.OrganaizationName)
    const [OrganaizationId, setOrganaizationId] = useState(orgdata.OrganaizationId)
    const [UserName, setUserName] = useState([])
    const [PhoneNumber, setPhoneNumber] = useState([])
    const [SelectedReviewers, setSelectedReviewName] = useState([])
    const [Image, setImage] = useState("");


    useEffect(() => {
        getReviewerList();
    }, []);

    const HandelAdmin = async (e) => {
        e.preventDefault();
        let PostData = { UserId, Password, OrganaizationName, OrganaizationId, UserName, PhoneNumber, ScreeningCenterList, Role, Image }
        try {
            await axios
                .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/postlogin", {
                    PostData
                })

                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    data(false)
                    alert(`${Role} Added Successfully`);
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);
                        if (error.response.data.message === 'UserId already exist') {
                            alert("UserId already exist")
                        }

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("Register not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const HandelCenter = async (e) => {
        e.preventDefault();
        console.log(SelectedReviewers)
        try {
            await axios
                .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/PostScreeningCenter", {
                    OrganaizationId, ScreeningCenter, SelectedReviewers
                })

                .then(response => {
                    // Handle successful response
                    data(false)
                    alert(`Screening Center ${response.data.message}`);
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);
                        if (error.response.data.message === 'UserId already exist') {
                            alert("UserId already exist")
                        }

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("Register not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const getReviewerList = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/GETReviewerNameWRTOrganaization?OrganaizationId=${OrganaizationId}`)
                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    setReviewNameList(response.data)
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/jpeg')) {
                alert('Please select a JPEG image.');
                return;
            }

            // Check if the file size is within the allowed limit (500KB)
            if (file.size > 500 * 1024) {
                alert('Image size must be less than 500KB.');
                return;
            }

            const reader = new FileReader();

            reader.onloadend = () => {
                // When reading is complete, set the base64 string
                setImage(reader.result.split(',')[1]);
            };

            // Read the file as a data URL, which will be a base64-encoded string
            reader.readAsDataURL(file);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <center>{header}</center>
            {
                Role !== "ScreeningCrenter" ?
                    <Form onSubmit={HandelAdmin}>
                        <Row className="mb-3 mt-3">
                            <Col md={6}>
                                <TextField
                                    disabled
                                    id="filled-disabled"
                                    label="Organaization Id"
                                    variant="outlined"
                                    value={OrganaizationId}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    disabled
                                    id="filled-disabled"
                                    label="Hospital Name"
                                    variant="outlined"
                                    value={OrganaizationName}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="User Id"
                                    variant="outlined"
                                    value={UserId}
                                    onChange={(e) => setUserId(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                            </Col>
                            <Col md={6}>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Password"
                                    variant="outlined"
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="User Name"
                                    variant="outlined"
                                    value={UserName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                            </Col>
                            <Col md={6}>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Phone Number"
                                    variant="outlined"
                                    value={PhoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                            </Col>
                        </Row>
                        {
                            Role === 'Operator' ?
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Screening Center</InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={ScreeningCenterList}
                                                label="Screening Center"
                                                onChange={(e) => setScreeningCenterList(e.target.value)}
                                            >
                                                {
                                                    ScreeningCrenterData?.map((item) =>
                                                        <MenuItem value={item.ScreeningCenter}>{item.ScreeningCenter}</MenuItem>

                                                    )
                                                }

                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row> : Role === 'ReportReview' ?
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Signature"
                                                className="mb-3" border="primary"
                                            >
                                                <Form.Control type="file" placeholder="Logo" style={{ borderColor: "#3D71B8" }} onChange={handleImageUpload} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Insert Image.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>

                                        </Col>
                                    </Row>
                                    : null
                        }


                        <div style={{ display: "flex", float: "right" }}>
                            <Button style={{ background: "#3871C2", width: "100px", marginRight: "20px", padding: "2px" }} type="Submit">Add</Button>
                            <Button style={{ background: "#3871C2", width: "100px" }} onClick={handleClose}>Cancel</Button>
                        </div>

                    </Form> :

                    <Form onSubmit={HandelCenter}>
                        <Row className="mb-3 mt-3">
                            <Col >
                                <TextField
                                    disabled
                                    id="filled-disabled"
                                    label="Organaization Id"
                                    variant="outlined"
                                    value={OrganaizationId}
                                    style={{ width: '100%' }}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col >
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Screening Center"
                                    variant="outlined"
                                    value={ScreeningCenter}
                                    onChange={(e) => setScreeningCenter(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col >
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={ReviewNameList? ReviewNameList: []}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    onChange={(event, selectedValues) => {
                                        setSelectedReviewName(selectedValues);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    style={{ width: "100%"}}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                        variant="outlined"
                                        label="Select Options"
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: null,  // This removes the dropdown icon
                                        }}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <div style={{ display: "flex", float: "right" }}>
                            <Button style={{ background: "#3871C2", width: "100px", marginRight: "20px", padding: "2px" }} type="Submit">Add</Button>
                            <Button style={{ background: "#3871C2", width: "100px" }} onClick={handleClose}>Cancel</Button>
                        </div>
                    </Form>


            }

        </div>
    )
}

export default AddUsers
