import React, { useState } from 'react';
import './Report.css';
import { Col, Row } from 'react-bootstrap';
import imageSrc from "../../../Images/RMD letterhead.png";

const Report = ({ data }) => {
    const [base64String, setbase64String] = useState(data.LogoBase64String)
    return (
        <div className='Report'>
            <Row style={{ position: "relative", padding: "12px", height: "100px" }} >
                <Col md={2} style={{ height: "100%" }}>
                    <img src={imageSrc} alt="Description of the image" width={"70%"} height={"90%"} />
                </Col>
                <Col md={8}>
                    <h3>{data.HospitalName}</h3>
                    {/* <lable style={{fontSize:"10px"}}>{data.Address1}</lable>
                    <lable style={{fontSize:"10px"}}>{data.Address2}</lable> */}
                </Col>
                <Col md={2} style={{ height: "100%" }}>
                    {base64String ? <img src={`data:image/jpeg;base64,${base64String}`} alt="Selected File" width={"70%"} height={"100%"} /> : null}
                </Col>
            </Row>
            <hr style={{ backgroundColor: '#3498db', height: '1px' }} />
            <div style={{height:"70px"}}>
                <Row style={{height:"33%"}}>
                    <Col md={8}>
                        <div style={{ textAlign: "left" }}>
                            <p style={{ marginLeft: "50px" }}> Patient Id :&nbsp;{data.PatientId}</p>
                            
                        </div>
                    </Col>
                    
                    <Col md={4} >
                        <div style={{ textAlign: "left" }}>
                            <p> Visit Id :&nbsp;{data.VisitId} </p>
                            
                        </div>

                    </Col>
                </Row>
                <Row style={{height:"33%"}}>
                    <Col md={8}>
                        <div style={{ textAlign: "left" }}>
                            <p style={{ marginLeft: "50px" }}> Patient Name :&nbsp;{data.PatientName}  </p>
                            
                        </div>
                    </Col>
                    
                    <Col md={4} >
                        <div style={{ textAlign: "left" }}>
                            <p  > Operator :&nbsp;{data.DoctorName} </p> 
                        </div>

                    </Col>
                </Row>

                <Row style={{height:"33%"}}>
                <Col md={8}>
                        <div style={{ textAlign: "left" }}>
                            <p style={{ marginLeft: "50px" }}>   Age :&nbsp;{data.Age} </p>
                        </div>
                    </Col>
                    
                    <Col md={4} >
                        <div style={{ textAlign: "left" }}>
                            <p >Date :&nbsp;{data.VisitDate} </p> 
                        </div>

                    </Col>
                </Row>

            </div>

            <hr style={{ backgroundColor: '#3498db', height: '2px' }} />
        </div>

    );
};

export default Report;