import React, { useState } from "react";
import { FloatingLabel, Form, Row, Col, Button } from "react-bootstrap";

const EditProfile = ({ data, Profile, handleClose, Role }) => {
    const [UserId, setUserId] = useState(Profile.UserId)
    const [OrganaizationName, setOrganaizationName] = useState(Profile.OrganaizationName)
    const [OrganaizationId, setOrganaizationId] = useState(Profile.OrganaizationId)
    const [UserName, setUserName] = useState(Profile.UserName)
    const [PhoneNumber, setPhoneNumber] = useState(Profile.PhoneNumber)
    const [Address, setAddress] = useState(Profile.Address)
    const [Status, setStatus] = useState(Profile.Status)
    const [CreatedDateTime, setCreatedDateTime] = useState(Profile.CreatedDateTime)
    const [base64String, setbase64String] = useState(Profile.Image)
    const [Image, setImage] = useState("");


    const HandelUpdate = async (e) => {
        e.preventDefault();
        // const localdata = localStorage.getItem("Data")
        // let UserData = JSON.parse(localdata)
        if (Role === "Admin") {
            data({ UserId, OrganaizationName, UserName, PhoneNumber, Address, OrganaizationId, Status, CreatedDateTime, Image })
        }
        else if (Role === "Operator") {
            let OperatorId = Profile.OperatorId
            data({ UserId, OrganaizationName, UserName, PhoneNumber, Address, OrganaizationId, Status, CreatedDateTime, OperatorId })
        }
        else if (Role === "ReportReview") {
            let ReviewId = Profile.ReviewId
            data({ UserId, OrganaizationName, UserName, PhoneNumber, Address, OrganaizationId, Status, CreatedDateTime, ReviewId, Image })
        }

    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/jpeg')) {
                alert('Please select a JPEG image.');
                return;
            }

            // Check if the file size is within the allowed limit (500KB)
            if (file.size > 500 * 1024) {
                alert('Image size must be less than 500KB.');
                return;
            }
            const reader = new FileReader();

            reader.onloadend = () => {
                // When reading is complete, set the base64 string
                setImage(reader.result.split(',')[1]);
            };

            // Read the file as a data URL, which will be a base64-encoded string
            reader.readAsDataURL(file);
        }
    };


    return (
        <div style={{ width: "100%" }}>
            <Form onSubmit={HandelUpdate}>
                <Row>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="User Id"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required disabled type="text" placeholder="User Id" style={{ borderColor: "#3D71B8" }} value={UserId} onChange={(e) => setUserId(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter UserId.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Organaizacion Id"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required disabled type="text" placeholder="User Namw" style={{ borderColor: "#3D71B8" }} value={OrganaizationId} onChange={(e) => setOrganaizationId(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter User Name.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="User Name"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="User Namw" style={{ borderColor: "#3D71B8" }} value={UserName} onChange={(e) => setUserName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter User Name.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Hospital Name"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Hospital Name" style={{ borderColor: "#3D71B8" }} value={OrganaizationName} onChange={(e) => setOrganaizationName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Hospital Name.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>

                </Row>
                <Row>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Phone Number"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Phone Number" style={{ borderColor: "#3D71B8" }} value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Phone Number.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Address"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Address" style={{ borderColor: "#3D71B8" }} value={Address} onChange={(e) => setAddress(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Address.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                {
                    Role === "Admin" ? <Row>
                        <Col md={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Hospital Logo"
                                className="mb-3" border="primary"
                            >
                                <Form.Control type="file" placeholder="Logo" style={{ borderColor: "#3D71B8" }} onChange={handleImageUpload} />
                                <Form.Control.Feedback type="invalid">
                                    Please Insert Image.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>

                            <label>
                                Hospital Logo
                            </label>
                            {base64String ? <img src={`data:image/jpeg;base64,${base64String}`} alt="Selected File" width={"100%"} height={100} /> : <div>Please Insert The Image</div>}

                        </Col>


                    </Row> : Role === "ReportReview" ? <Row>
                        <Col md={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Signature"
                                className="mb-3" border="primary"
                            >
                                <Form.Control type="file" placeholder="Logo" style={{ borderColor: "#3D71B8" }} onChange={handleImageUpload} />
                                <Form.Control.Feedback type="invalid">
                                    Please Insert Image.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>

                            <label>
                                Signature
                            </label>
                            {base64String ? <img src={`data:image/jpeg;base64,${base64String}`} alt="Selected File" width={"100%"} height={100} /> : <div>Please Insert The Image</div>}

                        </Col>
                    </Row> : null
                }

                <div style={{ display: "flex", float: "right" }}>
                    <Button style={{ background: "#3871C2", width: "100px", marginRight: "20px", padding: "2px" }} type="Submit">Update</Button>
                    <Button style={{ background: "#3871C2", width: "100px" }} onClick={handleClose}>Cancel</Button>
                </div>
            </Form>
        </div>
    )
}

export default EditProfile
