import React, { useEffect, useState } from 'react'
import DashBoard from '../GenralComponent/DashBoard'
import axios from 'axios';
import ReportDashBoard from './ReportDashBoard';

const OpDashBoard = () => {
  const loginstatus = localStorage.getItem("isOperatorLoggedIn")
  const data = localStorage.getItem("Data")
  let Operator = JSON.parse(data)
  console.log(Operator)


  const [loading, setLoading] = useState(true);
  const [Profile, setProfile] = useState()
  const [OperatorId, setOperatorId] = useState("")

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      await axios
        .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getprofile?UserId=${Operator.UserId}&Role=${Operator.Role}`)
        .then(response => {
          let orgdata = { OrganaizationId: response.data.OrganaizationId, OrganaizationName: response.data.OrganaizationName, OperatorId: response.data.OperatorId }
          localStorage.setItem('orgdata', JSON.stringify(orgdata))
          setOperatorId(response.data.OperatorId)
          setProfile(response.data)
          setLoading(false);
          console.log('Status Code:', response.status);
          console.log('Data:', response.data);
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);
          } else {
            // Some other error occurred
            console.error('Error:', error.message);
          }
        })
    }
    catch (err) {
      console.error(err);
    }

  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
      width: "100%",
      display: "flexbox"
    }}>
      {loginstatus ? <div style={{ height: "100%" }}>
        <DashBoard
          UserId={Profile.UserId}
          Profile={Profile}
          Role = "Operator"
          tablelist={<ReportDashBoard OperatorId={OperatorId}/>}
        />
      </div> : <div>
        {
          window.location.href = "/"
        }
      </div>}

    </div>
  )
}


export default OpDashBoard
