import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap'
import { MdPersonAddAlt } from 'react-icons/md';
import Optable from './Optable';
import RRTable from './RRTable';
import AddUsers from "./AddUsers";
import ModalBox from "../GenralComponent/ModelBox"
import ScreeningCrenter from './ScreeningCrenter';
import ReportDashBoard from './ReportDashBoard';

const Tables = ({ UserType }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [ScreeningCrenterData, setScreeningCrenterData] = useState([])
    const org = localStorage.getItem("orgdata")
    let orgdata = JSON.parse(org)
    let OrganaizationId = orgdata.OrganaizationId

    useEffect(() => {
        getScreeningCenter();
    }, []);

    const getScreeningCenter = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/GetScreeningCenter?OrganaizationId=${OrganaizationId}`)
                .then(response => {
                    // Handle successful response
                    setScreeningCrenterData(response.data[0].Centers)
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }
    const handledata = (data) => {
        setShow(data);
    }

    return (
        <div>
            {
                UserType !== "Dashboard" ? <div style={{ marginBottom: "20px", width: "100%", height: "7%" }}>
                    <Button style={{ width: "20%" }} variant={"secondary"} onClick={() => { setShow(true); }}> <MdPersonAddAlt size={20} />&emsp;Add {UserType}</Button>
                </div> : null
            }
            {
                UserType === "Dashboard" ?
                    <ReportDashBoard /> : UserType === "Operator" ?
                        <Optable /> : UserType === "Reviewer" ?
                            <RRTable /> : <ScreeningCrenter ScreeningCrenterData={ScreeningCrenterData} OrganaizationId={OrganaizationId} abc={()=>{getScreeningCenter()}}/>
            }
            <ModalBox
                show={show}
                handleClose={handleClose}
                message={UserType === "Operator" ? <AddUsers Role={"Operator"} data={handledata} handleClose={handleClose} header={"Add Operator"} ScreeningCrenterData={ScreeningCrenterData} /> : UserType === "Reviewer" ?
                    <AddUsers Role={"ReportReview"} data={handledata} handleClose={handleClose} header={"Add Reviewer"} /> : <AddUsers Role={"ScreeningCrenter"} data={handledata} handleClose={handleClose} header={"Add Screening Crenter"} />}
                Style={{ marginTop: "100px" }}
            />


        </div>
    )
}

export default Tables
