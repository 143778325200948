import React, { useEffect, useState } from 'react'
import axios from 'axios';
import '../GenralComponent/table.css';
import ReactPaginate from "react-paginate";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ModalBox from "../GenralComponent/ModelBox"
import EditLogindetails from "../GenralComponent/EditLogindetails";
import EditProfile from "../GenralComponent/EditProfile";
import Avatar from '@mui/material/Avatar';


const RRTable = () => {
    const org = localStorage.getItem("orgdata")
    let orgdata = JSON.parse(org)
    let OrganaizationId = orgdata.OrganaizationId
    const [rrCount, setrrCount] = useState(0)
    const [currentPage, setcurrentPage] = useState(1)
    const [ReviewTableData, setReviewTableData] = useState([])
    const handleClose = () => setShow(false);
    const handleProfileClose = () => setProfileShow(false);
    const [show, setShow] = useState(false);
    const [UserId, setUserId] = useState();
    let limit = 5
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [Profile, setProfile] = useState("");
    const [Profileshow, setProfileShow] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };


    useEffect(() => {
        getReviewerlist();
    }, [currentPage]);

    const handlePageClick = (data) => {
        let Page = data.selected + 1
        setcurrentPage(Page);
        console.log(Page);
    };


    const getReviewerlist = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getreviewerlist?page=${currentPage}&page_size=${limit}&OrganaizationId=${OrganaizationId}`)
                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    setReviewTableData(response.data.data);
                    setrrCount(response.data.count)
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleEditLoginProfile = (UserId) => {
        setUserId(UserId)
        setShow(true)
    }

    const getEditData = async (data) => {
        console.log(data);
        let UserId = data.UserId
        let Password = data.Password
        let OrganaizationId = data.OrganaizationId

        try {
            await axios
                .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updateloginpassword", {
                    UserId, Password, OrganaizationId
                })

                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    alert("Password Updated Successfully");
                    setShow(false);
                    getReviewerlist()
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("login not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleStatus = async (OrganaizationId, UserId, temstatus) => {
        console.log(OrganaizationId)
        let Status, URL
        if (temstatus === "Active") {
            Status = "InActive"
        } else {
            Status = "Active"
        }

        const confirmResult = window.confirm("Are you sure you want to update the status");
        if (confirmResult) {
            try {
                await axios
                    .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updatestatusofreviewer", {
                        OrganaizationId, UserId, Status
                    })

                    .then(response => {
                        // Handle successful response
                        console.log('Data:', response.data);

                        alert("Status Updated Successfully");
                        getReviewerlist();
                    })
                    .catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code outside the range of 2xx
                            console.log('Status Code:', error.response.status);
                            console.log('Data:', error.response.data);
                            alert("Status not Updated");
                            getReviewerlist();

                        } else {
                            // Some other error occurred
                            console.error('Error:', error.message);
                            alert("login not successful")
                        }
                    })
            }
            catch (err) {
                console.error(err);
            }
        }

    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${name.split(' ')[0][0]}`,
        };
    }

    const handleEditProfile = (item) => {
        setProfile(item)
        setProfileShow(true);
    }

    const HandelUpdateData = async (data, link) => {
        try {
            await axios
                .post(link, {
                    data
                })

                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    alert("Profile Updated Successfully");
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("login not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleUpdatedata = (data) => {
        console.log(data);
        
        HandelUpdateData(data, "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updatereviewprofile")
        
        setProfileShow(false);
    }

    return (
        <div>
            <table hover size="sm" style={{ textAlign: "center" }}>
                <tr style={{ backgroundColor: "#DEE2E6", height: "50px" }}>
                    <th style={{ width: "1%" }}></th>
                    <th>Reviewer Id</th>
                    <th>User Id</th>
                    <th>UserName</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "1%" }}></th>
                </tr>
                {
                    ReviewTableData?.map((item, i) =>
                        <tr key={i}>
                            <td><Avatar {...stringAvatar(item.UserName)} style={{ marginLeft: "20px" }} /></td>
                            <td>{item.ReviewId} </td>
                            <td>{item.UserId}</td>
                            <td>{item.UserName}</td>
                            <td>
                                <div style={{ marginLeft: "25%" }}>

                                    {item.Status === "Active" ? <div style={{ backgroundColor: "#AED4A8", borderRadius: "10px", width: "80px", textAlign: "center", fontSize: "16px", alignItems: "center" }}> {item.Status} </div> :
                                        <div style={{ backgroundColor: "#E49696", borderRadius: "10px", width: "80px", textAlign: "center", fontSize: "16px" }}>{item.Status}</div>}
                                </div>
                            </td>
                            <td>

                                <div className="dropdown">
                                    <button className="dropdown-button" onClick={toggleDropdown}>
                                        <BiDotsVerticalRounded style={{ float: "right" }} />
                                    </button>

                                    {isDropdownOpen && (
                                        <div className="dropdown-content left" onClick={closeDropdown}>
                                            <a onClick={() => handleStatus(item.OrganaizationId, item.UserId, item.Status)}>{item.Status === "Active" ? <div> InActive</div> : <div>Active</div>}</a>
                                            <a onClick={() => handleEditLoginProfile(item.UserId)}>Edit Login</a>
                                            <a onClick={() => handleEditProfile(item)}>Edit Profile</a>
                                        </div>
                                    )}
                                </div>

                            </td>

                        </tr>
                    )
                }
            </table>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={(Math.ceil(rrCount / limit))}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeLinkClassName={"active"}

            />
            <ModalBox
                show={show}
                handleClose={handleClose}
                message={<EditLogindetails UsersId={UserId} getEditData={getEditData} handleClose={handleClose} />}
                // close={true}
                Style={{ marginTop: "100px" }}
            />
            <ModalBox
                show={Profileshow}
                handleClose={handleClose}
                message={<EditProfile data={handleUpdatedata} Profile={Profile} handleClose={handleProfileClose} Role={"ReportReview"}/>}
                // close={true}
                Style={{ marginTop: "100px" }}
            />
        </div>
    )
}

export default RRTable
