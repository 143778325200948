import React, { useState } from "react";
import { FloatingLabel, Form, Row, Col, Button } from "react-bootstrap";
import axios from 'axios';


const AddAdminInputs = ({ getData, handleClose, header}) => {
    const [UserId, setUserId] = useState([])
    const [Password, setPassword] = useState([])
    const [OrganaizationName, setOrganaizationName] = useState([])
    const [UserName, setUserName] = useState([])
    const [PhoneNumber, setPhoneNumber] = useState([])
    const [Address, setAddress] = useState([])
    let Role = "Admin"

 
    const HandelAdmin = async (e) => {
        e.preventDefault();
        getData({UserId, Password, OrganaizationName, UserName, PhoneNumber, Address, Role})
        setUserId("")
        setPassword("")
        setOrganaizationName("")
        setUserName("")
        setPhoneNumber("")
        setAddress("")
    }


    return (
        <div style={{ width: "100%" }}>
            <center>{header}</center>
            <Form  onSubmit={HandelAdmin}>
                <Row className="mb-3 mt-3">
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="User Id"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="User Id" style={{ borderColor: "#3D71B8" }} value={UserId} onChange={(e) => setUserId(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter UserId.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Password"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Password" style={{ borderColor: "#3D71B8" }} value={Password} onChange={(e) => setPassword(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Password.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="User Name"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="User Namw" style={{ borderColor: "#3D71B8" }} value={UserName} onChange={(e) => setUserName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter User Name.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Hospital Name"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Hospital Name" style={{ borderColor: "#3D71B8" }} value={OrganaizationName} onChange={(e) => setOrganaizationName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Hospital Name.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Phone Number"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Phone Number" style={{ borderColor: "#3D71B8" }} value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Phone Number.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col md={6}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Address"
                            className="mb-3" border="primary"
                        >
                            <Form.Control required type="text" placeholder="Address" style={{ borderColor: "#3D71B8" }} value={Address} onChange={(e) => setAddress(e.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Address.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <div style={{display:"flex", float:"right", width:"50%"}}>
                    <Button style={{ background: "#3871C2",width:"30%", marginRight:"20px", height:"50%", position: "relative", left: "80px"}} type="Submit">Add</Button>
                    <Button style={{ background: "#3871C2", width:"30%", height:"50%", position: "relative", left: "80px"}} onClick={handleClose}>Cancel</Button>
                </div>
            </Form>
        </div>
    )
}

export default AddAdminInputs
