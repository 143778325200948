import React from 'react';
import "./Images.css";

const ShowImage = ({ data, Role, Payload }) => {
    return (
        <div className="grid-container">
            { Role === "Reviewer" ?
                    data?.map((item) =>
                        <div key={item.Id} style={{ marginLeft: "2%" }} className="grid-item">
                            <img
                                src={`data:image/jpeg;base64,${item.ImageData}`}
                                width="100%" height="100%"
                            />
                        </div>
                    ) 
                    :
                    Payload?.map((item) =>
                        <div key={item.Id} style={{ marginLeft: "2%" }} className="grid-item">
                            <img
                                src={`data:image/jpeg;base64,${item.ImageData}`}
                                width="100%" height="100%"
                            />
                        </div>
                    ) 
            }
        </div>
    );
};

export default ShowImage;
