import React, { useEffect, useState } from 'react'
import DashBoard from '../GenralComponent/DashBoard'
import axios from 'axios';
import NavigationBtn from './NavigationBtn';
import Tables from './Table';
import ReportCountDB from './ReportCountDB';

const SadDashBoard = () => {
  const loginstatus = localStorage.getItem("isLoggedIn")
  const data = localStorage.getItem("Data")
  let SuperAdmin = JSON.parse(data)
  const [TableData, setTableData] = useState([])
  const [PostData, setPostData] = useState()
  const [currentPage, setcurrentPage] = useState(1)
  const [Count, setCount] = useState(0)
  const [UserType, setUserType] = useState("Organaization");
  let limit = 5


  useEffect(() => {
    getAdminList();
  }, [currentPage]);

  useEffect(() => {
    PostingData();
  }, [PostData]);

  const handlePageClick = (data) => {
    // setcurrentPage(data.selected + 1)
    let Page = data.selected + 1
    setcurrentPage(Page);
    console.log(Page);
  };


  const getAdminList = async () => {
    try {
      await axios
        .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getadminlist?page=${currentPage}&page_size=${limit}`)
        .then(response => {
          // Handle successful response
          console.log('Data:', response.data);
          setTableData(response.data.data);
          setCount(response.data.count)
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);

          } else {
            // Some other error occurred
            console.error('Error:', error.message);
          }
        })
    }
    catch (err) {
      console.error(err);
    }
  }

  const PostingData = async () => {
    try {
      await axios
        .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/postlogin", {
          PostData
        })

        .then(response => {
          console.log('Data:', response.data);
          alert("Admin Added Successfully");
          console.log("hello world")
          getAdminList();
          console.log("hello world time 2")
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);
            if (error.response.data.message === 'UserId already exist') {
              alert("UserId already exist")
            }

          } else {
            // Some other error occurred
            console.error('Error:', error.message);
            alert("login not successful")
          }
        })
    }
    catch (err) {
      console.error(err);
    }
  }

  const getUserData = async (data) => {
    setPostData(data);
  }

  const handleStatus = async (OrganaizationId, temstatus) => {
    let Status
    if (temstatus === "Active") {
      Status = "Suspend"
    } else {
      Status = "Active"
    }
    const confirmResult = window.confirm("Are you sure you want to update the status");
    if (confirmResult) {
      try {
        await axios
          .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updatestatusofhospital", {
            OrganaizationId, Status
          })

          .then(response => {
            // Handle successful response
            console.log('Data:', response.data);

            alert("Status Updated Successfully");
            getAdminList();
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code outside the range of 2xx
              console.log('Status Code:', error.response.status);
              console.log('Data:', error.response.data);
              alert("Status not Updated");
              getAdminList();

            } else {
              // Some other error occurred
              console.error('Error:', error.message);
              alert("login not successful")
            }
          })
      }
      catch (err) {
        console.error(err);
      }
    }
  }

  const handleEditProfile = async (data) => {
    console.log(data);
    let UserId = data.UserId
    let Password = data.Password
    let OrganaizationId = data.OrganaizationId

    try {
      await axios
        .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/updateloginpassword", {
          UserId, Password, OrganaizationId
        })

        .then(response => {
          // Handle successful response
          console.log('Data:', response.data);
          alert("Password Updated Successfully");
          getAdminList()
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log('Status Code:', error.response.status);
            console.log('Data:', error.response.data);

          } else {
            // Some other error occurred
            console.error('Error:', error.message);
            alert("login not successful")
          }
        })
    }
    catch (err) {
      console.error(err);
    }
  }

  const handleTable = (usertype) => {
    setUserType(usertype)
  }

  return (
    <div style={{
      width: "100%",
      display: "flexbox"
    }}>
      {loginstatus ? <div style={{ height: "100%" }}>
        <DashBoard UserId={SuperAdmin.UserId}
          navigationbtn={<NavigationBtn getUserData={getUserData} handleTable={handleTable}/>}
          tablelist={UserType === "Organaization"? <Tables data={TableData}
            pageCount={(Math.ceil(Count / limit))}
            handlePageClick={handlePageClick}
            handleStatus={handleStatus}
            handleEditData={handleEditProfile}
          /> : <ReportCountDB/>}
        />

      </div> : <div>
        {
          window.location.href = "/"
        }
      </div>}

    </div>
  )
}

export default SadDashBoard
