import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ScreeningCrenter = ({ ScreeningCrenterData, OrganaizationId, abc }) => {

    const [ReviewNameList, setReviewNameList] = useState([]);
    const [selectedReviewNames, setSelectedReviewNames] = useState([]);

    useEffect(() => {
        getReviewerList();
    }, []);

    useEffect(() => {
        // Populate selectedReviewNames when ScreeningCrenterData changes
        setSelectedReviewNames(ScreeningCrenterData.map(item => item.ReviewName).filter(Boolean));
    }, [ScreeningCrenterData]);


    const getReviewerList = async () => {
        try {
            await axios
                .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/GETReviewerNameWRTOrganaization?OrganaizationId=${OrganaizationId}`)
                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data);
                    setReviewNameList(response.data)
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const HandleDropdown = async (ScreeningCenter, Reporter) => {
        try {
            await axios
                .post("https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/UpdateReporterinScreeningCenter", {
                    ScreeningCenter, Reporter, OrganaizationId
                })

                .then(response => {
                    // Handle successful response
                    console.log('Data:', response.data.message);
                    // alert(`${response.data.message}`);
                    abc()
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code outside the range of 2xx
                        console.log('Status Code:', error.response.status);
                        console.log('Data:', error.response.data);
                        if (error.response.data.message === 'UserId already exist') {
                            alert("UserId already exist")
                        }

                    } else {
                        // Some other error occurred
                        console.error('Error:', error.message);
                        alert("Register not successful")
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div style={{ height: "500px", overflowX: "hidden", overflowY: "auto" }}>
            <table style={{ textAlign: "center", width: "50%" }}>
                <tr style={{ backgroundColor: "#DEE2E6", height: "50px" }}>
                    <th style={{width:"30px"}}>Id</th>
                    <th style={{width:"300px"}}>Screening Crenter</th>
                    <th>Review Name</th>
                </tr>
                {
                    ScreeningCrenterData?.map((item, i) =>
                        <tr key={i} style={{ height: "50px" }}>
                            <td>{i + 1}</td>
                            <td>{item.ScreeningCenter}</td>
                            <td>
                            <Autocomplete
                                    multiple
                                    id={`autocomplete-${i}`}
                                    options={ReviewNameList ? ReviewNameList : []}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    value={Array.isArray(item.ReviewName) ? item.ReviewName : []}
                                    onChange={(event, newValue) => {
                                        const updatedReviewNames = [...selectedReviewNames];
                                        updatedReviewNames[i] = newValue;
                                        setSelectedReviewNames(updatedReviewNames);
                                        HandleDropdown(item.ScreeningCenter, newValue);
                                    }}
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params}/>
                                    )}
                                    disableClearable
                                />
                            </td>
                        </tr>
                    )
                }
            </table>
        </div>
    )
}

export default ScreeningCrenter
