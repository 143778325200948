import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Card } from "react-bootstrap";
import TotalTable from "./TotalTable";
import PendingTable from "./PendingTable";
import CompleteTable from "./CompleteTable";

const ReportDashBoard = () => {
  const org = localStorage.getItem("orgdata");
  let orgdata = JSON.parse(org);
  let OrganaizationId = orgdata.OrganaizationId;

  const [TotalReport, setTotalReport] = useState([]);
  const [TcurrentPage, setTcurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState();

  const [PendingReport, setPendingReport] = useState([]);
  const [PendingCount, setPendingCount] = useState();
  const [PcurrentPage, setPcurrentPage] = useState(1);

  const [TodayReport, setTodayReport] = useState([]);
  const [TodayCount, setTodayCount] = useState();
  const [TdcurrentPage, setTdcurrentPage] = useState(1);

  const [CompleteReport, setCompleteReport] = useState([]);
  const [CompleteCount, setCompleteCount] = useState();
  const [CcurrentPage, setCcurrentPage] = useState(1);

  const [ClickHandle, setClickHandle] = useState("Today");

  const [PatientRecordCount, setPatientRecordCount] = useState();

  const [pfrom_date, setpfrom_date] = useState("");
  const [pto_date, setpto_date] = useState("");
  const [cfrom_date, setcfrom_date] = useState("");
  const [cto_date, setcto_date] = useState("");
  const [tfrom_date, settfrom_date] = useState("");
  const [tto_date, settto_date] = useState("");
  const [loading, setLoading] = useState(true);

  let limit = 10;

  useEffect(() => {
    handelTotalReport();
    handelPendingReport();
    handelCompleteReport();
    handelTodaysReport();
    handelPatientRecord();
  }, [
    TcurrentPage,
    PcurrentPage,
    CcurrentPage,
    TdcurrentPage,
    pfrom_date,
    pto_date,
    cfrom_date,
    cto_date,
    tfrom_date,
    tto_date,
  ]);

  const handelTotalReport = async () => {
    try {
      await axios
        .get(
          `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getTotalReportList?OrganaizationId=${OrganaizationId}&page=${TcurrentPage}&page_size=${limit}&from_date=${tfrom_date}&to_date=${tto_date}&download=false`
        )
        .then((response) => {
          // Handle successful response
          setTotalReport(response.data.data);
          setTotalCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
          } else {
            // Some other error occurred
            console.error("Error:", error.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handelTodaysReport = async () => {
    let Status = "Pending";
    try {
      await axios
        .get(
          `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/GetTodaysReportList?OrganaizationId=${OrganaizationId}&Status=Pending&page=${TdcurrentPage}&page_size=${limit}`
        )
        .then((response) => {
          // Handle successful response
          setTodayReport(response.data.data);
          setTodayCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
          } else {
            // Some other error occurred
            console.error("Error:", error.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handelPendingReport = async () => {
    let Status = "Pending";
    try {
      await axios
        .get(
          `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getreportlist?OrganaizationId=${OrganaizationId}&Status=${Status}&page=${PcurrentPage}&page_size=${limit}&from_date=${pfrom_date}&to_date=${pto_date}`
        )
        .then((response) => {
          // Handle successful response
          setPendingReport(response.data.data);
          setPendingCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
          } else {
            // Some other error occurred
            console.error("Error:", error.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handelCompleteReport = async () => {
    let Status = "Complete";
    try {
      await axios
        .get(
          `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getreportlist?OrganaizationId=${OrganaizationId}&Status=${Status}&page=${CcurrentPage}&page_size=${limit}&from_date=${cfrom_date}&to_date=${cto_date}`
        )
        .then((response) => {
          // Handle successful response
          setCompleteReport(response.data.data);
          setCompleteCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
          } else {
            // Some other error occurred
            console.error("Error:", error.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handelPatientRecord = async () => {
    let Status = "Complete";
    try {
      await axios
        .get(
          `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getPatientRecord?OrganaizationId=${OrganaizationId}`
        )
        .then((response) => {
          // Handle successful response
          console.log("Data:", response.data);
          setPatientRecordCount(response.data.Count);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
          } else {
            // Some other error occurred
            console.error("Error:", error.message);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleTotalPageClick = (data) => {
    // setcurrentPage(data.selected + 1)
    let Page = data.selected + 1;
    setTcurrentPage(Page);
    console.log(Page);
  };
  const handlePendingPageClick = (data) => {
    // setcurrentPage(data.selected + 1)
    let Page = data.selected + 1;
    setPcurrentPage(Page);
    setTdcurrentPage(Page);
  };
  const handleCompletePageClick = (data) => {
    // setcurrentPage(data.selected + 1)
    let Page = data.selected + 1;
    setCcurrentPage(Page);
  };

  const handlePendingfilter = (from_date, to_date) => {
    setpfrom_date(from_date);
    setpto_date(to_date);
    handelPendingReport();
  };

  const handleCompletefilter = (from_date, to_date) => {
    setcfrom_date(from_date);
    setcto_date(to_date);
    handelCompleteReport();
  };
  const handleTotalfilter = (from_date, to_date) => {
    settfrom_date(from_date);
    settto_date(to_date);
    handelTotalReport();
  };

  const handleRefresh = (data) => {
    setpfrom_date("");
    setpto_date("");
    setcfrom_date("");
    setcto_date("");
    settfrom_date("");
    settto_date("");
    if (data === "Pending") {
      handelPendingReport();
    } else if (data === "Complete") {
      handelCompleteReport();
    } else {
      handelTotalReport();
    }
  };

  return (
    <div>
      <Row className="mb-2" style={{ textAlign: "center", height: "30%" }}>
        <Col sm={2} style={{ cursor: "pointer", marginLeft: "3%" }}>
          <Card
            style={{
              width: "100%",
              height: "130px",
              backgroundColor: "#d4e0ec",
              color: "#004E9B",
              border: ClickHandle === "Today" ? "3px solid #5f5959" : "none",
            }}
            className="mb-2"
            onClick={() => {
              setLoading(true);
              setClickHandle("Today");
              setTdcurrentPage(1);
              handleRefresh("Today");
            }}
          >
            <Card.Body>
              <h5>Today's Request</h5>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <Card.Title>
                  <h3>{TodayCount ? <div>{TodayCount}</div> : 0} </h3>{" "}
                </Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} style={{ cursor: "pointer", marginLeft: "3%" }}>
          <Card
            style={{
              width: "100%",
              height: "130px",
              backgroundColor: "#D3A1A1",
              color: "#9E0000",
              border: ClickHandle === "Pending" ? "3px solid #5f5959" : "none",
            }}
            className="mb-2"
            onClick={() => {
              setLoading(true);
              setClickHandle("Pending");
              setPcurrentPage(1);
              handleRefresh("Pending");
            }}
          >
            <Card.Body>
              <h5>Pending</h5>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <Card.Title>
                  <h3> {PendingCount ? <div>{PendingCount}</div> : 0} </h3>{" "}
                </Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={2} style={{ cursor: "pointer", marginLeft: "3%" }}>
          <Card
            style={{
              width: "100%",
              height: "130px",
              backgroundColor: "#92C8AA",
              color: "#00833D",
              border: ClickHandle === "Complete" ? "3px solid #5f5959" : "none",
            }}
            className="mb-2"
            onClick={() => {
              setLoading(true);
              setClickHandle("Complete");
              setCcurrentPage(1);
              handleRefresh("Complete");
            }}
          >
            <Card.Body>
              <h5>Completed</h5>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <Card.Title>
                  <h3> {CompleteCount ? <div>{CompleteCount}</div> : 0} </h3>{" "}
                </Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={2} style={{ cursor: "pointer", marginLeft: "3%" }}>
          <Card
            style={{
              width: "100%",
              height: "130px",
              backgroundColor: "#C9B9D6",
              color: "#55019A",
              border: ClickHandle === "Total" ? "3px solid #5f5959" : "none",
            }}
            className="mb-2"
            onClick={() => {
              setLoading(true);
              setClickHandle("Total");
              setTcurrentPage(1);
              handleRefresh("Total");
            }}
          >
            {/* <Card.Header>Total</Card.Header> */}
            <Card.Body>
              <h5>Total </h5>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <Card.Title>
                  <h3> {TotalCount ? <div>{TotalCount}</div> : 0}</h3>{" "}
                </Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={2} style={{ marginLeft: "3%" }}>
          <Card
            style={{
              width: "100%",
              height: "130px",
              backgroundColor: "#FFE5D4",
              color: "#CB8501",
            }}
            className="mb-2"
            // onClick={() => { setClickHandle("Total"); setTcurrentPage(1) }}
          >
            {/* <Card.Header>Total</Card.Header> */}
            <Card.Body>
              <h5>Total Patient Registered</h5>
              <Card.Title>
                <h3> {PatientRecordCount}</h3>{" "}
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row style={{ height: "70%" }}>
        <Col sm={12}>
          {ClickHandle === "Today" ? (
            <div>
              <PendingTable
                Loading={loading}
                data={TodayReport}
                limit={limit}
                PendingCount={TodayCount}
                handlePendingPageClick={handlePendingPageClick}
                OrganaizationId={OrganaizationId}
              />
            </div>
          ) : ClickHandle === "Pending" ? (
            <div>
              <PendingTable
                Loading={loading}
                data={PendingReport}
                limit={limit}
                PendingCount={PendingCount}
                handlePendingPageClick={handlePendingPageClick}
                OrganaizationId={OrganaizationId}
                abc={"Pending"}
                OnFilter={handlePendingfilter}
                OnRefresh={() => {
                  handleRefresh("Pending");
                }}
              />
            </div>
          ) : ClickHandle === "Total" ? (
            <div>
              <TotalTable
                Loading={loading}
                data={TotalReport}
                limit={limit}
                TotalCount={TotalCount}
                handleTotalPageClick={handleTotalPageClick}
                OrganaizationId={OrganaizationId}
                OnFilter={handleTotalfilter}
                OnRefresh={() => {
                  handleRefresh("Total");
                }}
              />
            </div>
          ) : (
            <div>
              <CompleteTable
                Loading={loading}
                data={CompleteReport}
                limit={limit}
                CompleteCount={CompleteCount}
                handleCompletePageClick={handleCompletePageClick}
                OnFilter={handleCompletefilter}
                OnRefresh={() => {
                  handleRefresh("Complete");
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReportDashBoard;
