import React from "react";
import './nav.css';
import { TbLayoutDashboard } from 'react-icons/tb';
import { MdPersonAddAlt } from 'react-icons/md';

const NavigationBtn = ({handleTable}) => {
    return (
        <div className="vertical-nav-bar">
            <a onClick={() => handleTable("Dashboard")}> <TbLayoutDashboard />&emsp;DashBoard</a>
            <a onClick={() => handleTable("Operator")}><MdPersonAddAlt />&emsp;Operators</a>
            <a onClick={() => handleTable("Reviewer")}><MdPersonAddAlt />&emsp;Reviewers</a>
            <a onClick={() => handleTable("Center")}><MdPersonAddAlt />&emsp;Screening Centers</a>
        </div>
    )
}
export default NavigationBtn
