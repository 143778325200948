import React, { useState } from "react";
import './nav.css';
import ModalBox from "../GenralComponent/ModelBox"
import AddAdminInputs from "./AddAdminInputs";
import { TbLayoutDashboard } from 'react-icons/tb';
import { MdPersonAddAlt } from 'react-icons/md';


const NavigationBtn = ({ getUserData,handleTable  }) => {
    const [show, setShow] = useState(false);
    const [Data, setData] = useState();
    const handleClose = () => setShow(false);

    const handleAddAdmin = (e) => {
        e.preventDefault();
        setShow(true);
    }

    const handleData = (data) => {
        setShow(false);
        setData(data)
    }

    if (Data) {
        console.log(Data);
        getUserData(Data)
        setData("")
    }

    return (
        <div className="vertical-nav-bar">
            {/* <a href="/#/SuperAdminDashboard"> <TbLayoutDashboard/>&emsp;DashBoard</a> */}
            {/* <a onClick={() => handleTable("Dashboard")}> <TbLayoutDashboard/>&emsp;Dashboard</a> */}
            <a onClick={() => handleTable("Organaization")}> <TbLayoutDashboard/>&emsp;Dashboard</a>
            <a onClick={handleAddAdmin}><MdPersonAddAlt/>&emsp;Add Admin</a>
            <ModalBox
                show={show}
                handleClose={handleClose}
                message={<AddAdminInputs getData={handleData} handleClose={handleClose} header={"Add Admin"}/>}
                // close={true}
                Style={{ marginTop: "100px" }}
            />
        </div>
    )
}
export default NavigationBtn
