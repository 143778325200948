import React, { useState } from 'react'
import "../App.css";
import TilgangaLogo from "../../Images/Tilganga.png";
import RightCamLogo from "../../Images/RightCamLogo.jpg";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import ModalBox from "../GenralComponent/ModelBox"
import ForgotPassword from './ForgotPassword';


const Login = ({webLocation}) => {
    const [UserId, setUserId] = useState([])
    const [Password, setPassword] = useState([])
    const [validated, setValidated] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('isLoggedIn'));
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    let navigate = useNavigate();

    const HandelSubmit = async (e) => {
        const form = e.currentTarget;
        
        e.preventDefault();
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        setValidated(true);
        if (UserId === "" || Password === "") {
            alert("Please enter all required fields")
        }
        else {
            try {
                await axios
                    .get(`https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getlogin?UserId=${UserId}&Password=${Password}`
                    )
                    .then(response => {
                        // Handle successful response
                        console.log('Data:', response.data);
                        setIsLoggedIn(true);
                        let Role =response.data.Role
                        let data = {UserId, Role}
                        localStorage.setItem('Data', JSON.stringify(data));
                        if (response.data.Role === 'SuperAdmin') {
                            localStorage.setItem('isLoggedIn', 'true');
                            navigate("/SuperAdminDashboard");
                        }
                        else if (response.data.Role === 'Admin') {
                            localStorage.setItem('isAdminLoggedIn', 'true');
                            navigate("/AdminDashboard");
                        }
                        else if (response.data.Role === 'Operator') {
                            localStorage.setItem('isOperatorLoggedIn', 'true');
                            navigate("/OperatorDashboard");
                        }
                        else if (response.data.Role === 'ReportReview'){
                            localStorage.setItem('isReviewLoggedIn', 'true');
                            navigate("/ReviewDashboard");
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code outside the range of 2xx
                            console.log('Status Code:', error.response.status);
                            console.log('Data:', error.response.data);
                            alert("login not successful, Please Enter Valid Id Password")
                        } else {
                            // Some other error occurred
                            console.error('Error:', error.message);
                            alert("login not successful")
                        }
                    })
            }
            catch (err) {
                console.error(err);
            }
        }

        setUserId("")
        setPassword("")
    }

    return (
        <div
            style={{
                // window.location.href
                backgroundImage: webLocation === "https://tilganga.righttelemed.com/" ?`url(${TilgangaLogo})` :`url(${RightCamLogo})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                width: "100%",
                display: "flexbox",
            }}
        >
            <Card style={{
                width: '25rem',
                height: 'auto',
                margin: '7%',
                position: "relative",
                top: "2%",
                backgroundColor: "#F5F8FB",
                boxShadow: "15px 10px 30px 5px #BDD2F3",
            }}>
                <Card.Body>
                    <Card.Title style={{
                        fontSize: "32px",
                        color: "#3D71B8",
                        textAlign: "center",
                    }}>Login</Card.Title>
                    <br />
                    <Form gap={2} onSubmit={HandelSubmit} noValidate validated={validated}>
                        <div>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="User Id"
                                className="mb-3" border="primary"
                            >
                                <Form.Control required type="text" placeholder="User Id" style={{ borderColor: "#3D71B8" }} value={UserId} onChange={(e) => setUserId(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter UserId.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </div>
                        <div>
                            <FloatingLabel controlId="floatingPassword" label="Password">
                                <Form.Control required type="password" placeholder="Password" style={{ borderColor: "#3D71B8" }} value={Password} onChange={(e) => setPassword(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Password.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </div>
                       

                        <div className="mt-5 mb-4">
                            <Button style={{ background: "#3871C2", width: "100%" }} type="Submit">Login</Button>
                            <p onClick={()=>{setShow(true)}} className='mt-2' style={{float:"right", cursor:"pointer", color:"#1E58B4"}}>Forgot Password</p>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <ModalBox
                show={show}
                handleClose={handleClose}
                message={<ForgotPassword flag={1} handleClose={handleClose}/>}
                close={true}
                Style={{ marginTop: "100px" }}
            />
        </div>
    );
};

export default Login;
