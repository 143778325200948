import React, { useEffect, useState } from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";

const formatDate = (dateTimeString) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Asia/Kolkata", // Use Indian Standard Time (IST) timezone
  };

  const formattedDate = new Date(dateTimeString).toLocaleString(
    "en-GB",
    options
  );

  return formattedDate;
};

const PendingTable = ({
  data,
  limit,
  PendingCount,
  handlePendingPageClick,
  abc,
  OnFilter,
  OnRefresh,
  Loading
}) => {

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },

      children: `${name.split(" ")[0][0]}`,
    };
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {abc === "Pending" ? (
        <div style={{ height: "10%" }}>
          <br />
          <lable>From:</lable>{" "}
          <input
            style={{
              height: "40px",
              border: "1px solid black",
              backgroundColor: "#EAEEF3",
              borderRadius: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            type="date"
            value={from_date}
            onChange={(e) => setfrom_date(e.target.value)}
          />
          &ensp; To:{" "}
          <input
            type="date"
            value={to_date}
            style={{
              height: "40px",
              border: "1px solid black",
              backgroundColor: "#EAEEF3",
              borderRadius: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            onChange={(e) => setto_date(e.target.value)}
          />{" "}
          &ensp;
          <Button
            style={{ width: "4%", height: "43px" }}
            onClick={() => {
              OnFilter(from_date, to_date);
            }}
          >
            <FaSearch />
          </Button>{" "}
          &ensp;
          <Button
            style={{ width: "4%", height: "43px" }}
            onClick={() => {
              setfrom_date("");
              setto_date("");
              OnRefresh();
            }}
          >
            <HiOutlineRefresh size={20} />
          </Button>
        </div>
      ) : null}

      <div style={{ height: "100%" }}>
        {Loading ? (
          <div>Loading....</div>
        ) : (
          <table hover style={{ textAlign: "center" }} className="mb-2">
            <tr
              style={{
                backgroundColor: "#DEE2E6",
                height: "35px",
                color: "#B5B5C3",
              }}
            >
              <th style={{ width: "1%" }}></th>
              <th>Patient Id</th>
              <th>Patient Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Requested Date & Time</th>
              <th>Screening Center</th>
              <th style={{ width: "10%" }}>Status</th>
              {/* <th>Assign To</th> */}

              <th>View</th>
            </tr>

            {data && data.length > 0 ? (
              data?.map((item, i) => (
                <tr key={i} style={{ height: "35px" }}>
                  <td style={{ paddingLeft: "10px" }}>
                    <Avatar {...stringAvatar(item.PatientName)} />
                  </td>
                  <td>{item.PatientId}</td>
                  <td>{item.PatientName}</td>
                  <td>{item.Age}</td>
                  <td>{item.Gender}</td>
                  <td>{formatDate(item.SentReportDateTime)}</td>
                  <td>{item.ScreeningCenter}</td>
                  <td>
                    <div style={{ marginLeft: "25%" }}>
                      {item.Status === "Complete" ? (
                        <div
                          style={{
                            backgroundColor: "#AED4A8",
                            borderRadius: "5px",
                            width: "80px",
                            textAlign: "center",
                            fontSize: "16px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {item.Status}{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#E49696",
                            borderRadius: "5px",
                            width: "80px",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {item.Status}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* <td>
                                        <select value={ReviewName} style={{ width: "70%", borderRadius: "5px" }}
                                            onChange={(e) => { HandleDropdown(item.ReportId, e.target.value) }}
                                        >
                                            {
                                                item.Assignee ? <option value={item.Assignee}>{item.Assignee}</option> : <option value="">--Select The Assign To--</option>
                                            }

                                            {
                                                ReviewNameList?.map((item) =>
                                                    <option value={item}>{item}</option>

                                                )
                                            }

                                        </select>
                                    </td> */}

                  <td>
                    <Link to={item.ReportId} style={{ color: "#2E86C1" }}>
                      <a>
                        <IoIosArrowRoundForward
                          size={40}
                          style={{ color: "black" }}
                        />
                      </a>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </table>
        )}
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={Math.ceil(PendingCount / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePendingPageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default PendingTable;
