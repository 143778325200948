import React, { useRef, useEffect, useState } from "react";
import "./Patientreports.css";
import Report from "./Report";
import ShowImage from "./ShowImage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReportDiagosis from "./ReportDiagosis";
import ModalBox from "../../GenralComponent/ModelBox";
import History from "./History";
import ShowModleImage from "./ShowModleImage";

const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.oIndexedDB ||
  window.shimIndexedDB;

const CreateCollectionIndexedDB = () => {
  if (!idb) {
    console.log("This browser does not support IndexedDB");
    return;
  }

  const request = idb.open("test-db", 2);

  request.onerror = (event) => {
    console.log("Error", event);
    console.log("An error occurred");
  };

  request.onupgradeneeded = (event) => {
    const db = request.result;
    if (!db.objectStoreNames.contains("userData")) {
      db.createObjectStore("userData", {
        keyPath: "ReportId",
      });
    }
  };

  request.onsuccess = () => {
    console.log("Opened successfully");
  };
};

function PatientReport({ Annotate, handleAnnotate, Role }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [PaylodeLen, setPaylodeLen] = useState();
  const { ReportId } = useParams();
  const [Historyshow, setHistoryShow] = useState(false);
  const [showImage, setshowImage] = useState(false);
  const [ValidOp, setValidOp] = useState(true);
  const handleClose = () => setHistoryShow(false);
  const handleImageClose = () => setshowImage(false);
  const [LeftEyeDiagnosis, setLeftEyeDiagnosis] = useState("");
  const [RightEyeDiagnosis, setRightEyeDiagnosis] = useState("");
  const [Recommendations, setRecommendations] = useState("");
  const [Payload, setPayload] = useState([]);
  const [ShowImageModle, setShowImageModle] = useState();
  const [SelectedImages, setSelectedImages] = useState([]);

  const Metadata = [
    "right image, anterior",
    "left image, anterior",
    "right image, posterior",
    "left image, posterior",
  ];

  const pdfRef = useRef(null);
  const org = localStorage.getItem("orgdata");
  let Review = JSON.parse(org);
  let navigate = useNavigate();

  const uploadPdf = async (PdfBase64) => {
    let OrganaizationId = Review.OrganaizationId;
    let ReviewId = Review.ReviewId;
    console.log(ReviewId);
    try {
      await axios
        .post(
          "https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/postreviewpdf",
          {
            OrganaizationId,
            ReportId,
            ReviewId,
            PdfBase64,
            LeftEyeDiagnosis,
            RightEyeDiagnosis,
            Recommendations,
          }
        )

        .then((response) => {
          // Handle successful response
          console.log("Data:", response.data);
          alert(`Report Uploded Successfully`);
          handleCancelClick();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log("Status Code:", error.response.status);
            console.log("Data:", error.response.data);
            handleCancelClick();
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSaveClick = async () => {
    if (SelectedImages.length >= 1) {
      const confirmSave = window.confirm("Do you want to submit the report?");
      if (confirmSave) {
        const pdf = new jsPDF();

        html2canvas(pdfRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          if (PaylodeLen > 2) {
            pdf.addImage(
              imgData,
              "PNG",
              0,
              0,
              pdf.internal.pageSize.getWidth(),
              pdf.internal.pageSize.getHeight()
            );
          } else {
            pdf.addImage(
              imgData,
              "PNG",
              0,
              0,
              pdf.internal.pageSize.getWidth(),
              210
            );
          }
          // pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
          const pdfBase64 = pdf.output("dataurlstring");
          const base64String = pdfBase64.split(",")[1];
          uploadPdf(base64String);
        });
      }
    } else {
      alert("Please select the Images");
    }
  };

  const handleCancelClick = () => {
    localStorage.removeItem("Payload");

    localStorage.removeItem("ImagePayload");
    handleAnnotate(false);
    if (Role === "Admin") {
      navigate("/AdminDashboard");
    } else {
      navigate("/ReviewDashboard");
    }
  };

  useEffect(() => {
    CreateCollectionIndexedDB();
    getdata();
    setLoading(true);
  }, []);

  const setImageData = (ImageDataList) => {
    var Reposterior = null;
    var Leposterior = null;
    var Reanterior = null;
    var Leanterior = null;

    for (let i = 0; i < ImageDataList.length; i++) {
      if (ImageDataList[i].Metadata === "Reposterior" && Reposterior === null) {
        Reposterior = ImageDataList[i];
        continue;
      }
      if (ImageDataList[i].Metadata === "Leposterior" && Leposterior === null) {
        Leposterior = ImageDataList[i];
        continue;
      }
      if (ImageDataList[i].Metadata === "Reanterior" && Reanterior === null) {
        Reanterior = ImageDataList[i];
        continue;
      }
      if (ImageDataList[i].Metadata === "Leanterior" && Leanterior === null) {
        Leanterior = ImageDataList[i];
        continue;
      }
    }
    const data = [Reposterior, Leposterior, Reanterior, Leanterior].filter(
      (item) => item !== null
    );

    if (data.length !== 4) {
      setValidOp(false);
    }
    console.log(data);
    setSelectedImages(data);
  };

  const getdata = async () => {
    if (Annotate === false) {
      try {
        await axios
          .get(
            `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/reviewgetpatientreport?OrganaizationId=${Review.OrganaizationId}&ReportId=${ReportId} `
          )
          .then((response) => {
            // Handle successful response
            console.log(response.data);
            setData(response.data);
            setPaylodeLen(response.data.Payload.length);

            if (Metadata.includes(response.data.Payload[0].Metadata)) {
              const metadataMapping = {
                "right image, anterior": "Reanterior",
                "left image, anterior": "Leanterior",
                "right image, posterior": "Reposterior",
                "left image, posterior": "Leposterior",
              };

              var abc = response.data.Payload.map((item) => {
                return {
                  ...item,
                  Metadata: metadataMapping[item.Metadata] || item.Metadata,
                };
              });

              setImageData(abc);
              setPayload(abc);
            } else {
              setPayload(response.data.Payload);
            }

            setLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code outside the range of 2xx
              console.log("Status Code:", error.response.status);
              console.log("Data:", error.response.data);
            } else {
              // Some other error occurred
              console.error("Error:", error.message);
            }
          });
      } catch (err) {
        console.error(err);
      }
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  const fetchReportDiagosisData = (left, right, recommend) => {
    setLeftEyeDiagnosis(left);
    setRightEyeDiagnosis(right);
    setRecommendations(recommend);
  };

  const handleCheckboxChange = (Image) => {
    const maxSelection = 4;
    if (ValidOp === false) {

      if (SelectedImages.find((selected) => selected.Id === Image.Id)) {
        // If the Image is already selected, remove it from the array
        setSelectedImages(
          SelectedImages.filter((selected) => selected.Id !== Image.Id)
        );
      } else if (SelectedImages.length < maxSelection) {
        // If the maximum limit is not reached, add the Image to the array
        setSelectedImages([...SelectedImages, Image]);
      }else {
        alert(
          "Please note: You are only allowed to enter up to 4 data entries."
        );
      }
    } else {
      if (SelectedImages.find((selected) => selected.Id === Image.Id)) {
        // If the Image is already selected, remove it from the array
        setSelectedImages(
          SelectedImages.filter((selected) => selected.Id !== Image.Id)
        );
      } else if (SelectedImages.length < maxSelection) {
        const metadataAlreadySelected = SelectedImages.some(
          (selected) => selected.Metadata === Image.Metadata
        );
        if (metadataAlreadySelected) {
          alert("The data for " + Image.Metadata + " is already present.");
          return; // Stop further execution
        } else {
          let insertIndex = 0;
          switch (Image.Metadata) {
            case "Leposterior":
              insertIndex = 1;
              break;
            case "Reanterior":
              insertIndex = 2;
              break;
            case "Leanterior":
              insertIndex = 3;
              break;
          }
          setSelectedImages((prevSelectedImages) => {
            const newSelectedImages = [...prevSelectedImages];
            newSelectedImages.splice(insertIndex, 0, Image);
            return newSelectedImages.slice(0, maxSelection); // Ensure max selection limit
          });
        }
      } else {
        alert(
          "Please note: You are only allowed to enter up to 4 data entries."
        );
      }
    }
  };

  const handleViewImage = (ImageURL) => {
    setShowImageModle(ImageURL);
    setshowImage(true);
  };

  return (
    <div>
      <ModalBox
        show={Historyshow}
        handleClose={handleClose}
        message={
          <History data={data.ClinicalHistory} handleClose={handleClose} />
        }
        Style={{ marginTop: "100px" }}
      />
      <ModalBox
        show={showImage}
        handleClose={handleImageClose}
        message={
          <ShowModleImage
            ImageURL={ShowImageModle}
            handleClose={handleImageClose}
          />
        }
        close={true}
        Style={{ marginTop: "40px", width: "1500px", height: "800px" }}
      />
      <Row className="Ap">
        <Col
          sm={Role === "Reviewer" ? 9 : 10}
          style={{
            height: "100%",
            display: "inline",
            backgroundColor: "white",
          }}
          ref={pdfRef}
        >
          <Report data={data} />
          <ShowImage data={SelectedImages} Role={Role} Payload={Payload} />
          <ReportDiagosis fetchReportDiagosisData={fetchReportDiagosisData} />
        </Col>
        {Role === "Reviewer" ? (
          <Col sm={1}>
            {Payload?.map((item) => (
              <div
                style={{ position: "relative" }}
                onDoubleClick={() => {
                  handleViewImage(item.ImageData);
                }}
              >
                <input
                  type="checkbox"
                  checked={SelectedImages.some(
                    (selected) => selected.Id === item.Id
                  )}
                  onChange={() => handleCheckboxChange(item)}
                  style={{
                    position: "absolute",
                    left: "3px",
                    top: "3px",
                    transform: "scale(1.5)",
                    cursor: "pointer",
                  }}
                />
                <div
                  key={item.Id}
                  style={{
                    height: "80px",
                    width: "100%",
                    marginTop: "15px",
                    opacity: SelectedImages.some(
                      (selected) => selected.Id === item.Id
                    )
                      ? "0.5"
                      : "1", // Reverse the opacity setting
                    pointerEvents: SelectedImages.some(
                      (selected) => selected.Id === item.Id
                    )
                      ? "none"
                      : "auto", // Reverse the pointer events setting
                  }}
                >
                  {" "}
                  <img
                    alt=""
                    src={`data:image/jpeg;base64,${item.ImageData}`}
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              </div>
            ))}
          </Col>
        ) : null}

        <Col sm={2}>
          <div className="tools">
            <button
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "100%",
              }}
              onClick={() => {
                setHistoryShow(true);
              }}
            >
              View History
            </button>
            {Role === "Reviewer" ? (
              <button
                style={{
                  borderRadius: "10px",
                  marginBottom: "10px",
                  width: "100%",
                }}
                onClick={handleSaveClick}
              >
                Submit
              </button>
            ) : null}
            <button
              style={{ borderRadius: "10px", width: "100%" }}
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>

          {Role === "Reviewer" ? (
            <div className="bottom-container">
              <h5>Instructions for reporting:</h5>
              <br />
              * Tick the checkbox on the thumbnails to select the image
              <br />
              * Double click on the thumbnails to View the image in full screen
              mode.
              <br />
              * For reporting, minimum 1 and maximum 4 images should be selected
              <br />
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default PatientReport;
