import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ShowModleImage = ({ ImageURL, handleClose }) => {
  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col sm={12}>
          <img
            alt=""
            src={`data:image/jpeg;base64,${ImageURL}`}
            style={{ height: "520px", width: "100%" }}
          />
        </Col>
      </Row>

      
    </div>
  )
}

export default ShowModleImage
