import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import axios from "axios";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";

const formatDate = (dateTimeString) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Asia/Kolkata", // Use Indian Standard Time (IST) timezone
  };

  const formattedDate = new Date(dateTimeString).toLocaleString(
    "en-GB",
    options
  );

  return formattedDate;
};

const TotalTable = ({
  data,
  limit,
  TotalCount,
  handleTotalPageClick,
  OrganaizationId,
  OnFilter,
  OnRefresh,
  Loading
}) => {
  const [jsonData, setJsonData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  useEffect(() => {
    // Fetch JSON data from the API
    const fetchData = async () => {
      try {
        await axios
          .get(
            `https://55z1gvjomk.execute-api.ap-south-1.amazonaws.com/dev/getTotalReportList?OrganaizationId=${OrganaizationId}&page=${1}&page_size=${limit}&from_date=${from_date}&to_date=${to_date}&download=true`
          )
          .then((response) => {
            // Handle successful response
            setJsonData(response.data.data);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code outside the range of 2xx
              console.log("Status Code:", error.response.status);
              console.log("Data:", error.response.data);
            } else {
              // Some other error occurred
              console.error("Error:", error.message);
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [from_date, to_date]);

  useEffect(() => {
    // Convert JSON data to Excel format
    if (Array.isArray(jsonData) && jsonData.length > 0) {
      const dataForExcel = jsonData?.map((item) => ({
        PatientId: item.PatientId || "",
        VisitId: item.VisitId || "",
        PatientName: item.PatientName || "",
        Age: item.Age || "",
        Gender: item.Gender || "",
        Phone: item.Phone || "",
        Assignee: item.Assignee || "",
        Impression: item.Impression || "",
        visitDate: item.visitDate || "",
        LeftEyeDiagnosis: item.LeftEyeDiagnosis || "",
        RightEyeDiagnosis: item.RightEyeDiagnosis || "",
        Recommendations: item.Recommendations || "",
        ScreeningCenter: item.ScreeningCenter || "",
        SentReportDateTime: formatDate(item.SentReportDateTime) || "",
        ReviewedDateTime: formatDate(item.ReviewedDateTime) || "",
        TestDoneBy: item.TestDoneBy || "",
        Status: item.Status || "",
      }));

      setExcelData(dataForExcel);

      const excelColumns = Object.keys(dataForExcel[0]).map((key) => ({
        dataField: key,
        text: key,
      }));

      // Count pending and complete reports
      const pendingReports = dataForExcel.filter(
        (item) => item.Status === "Pending"
      );
      const completeReports = dataForExcel.filter(
        (item) => item.Status === "Complete"
      );
      setPendingCount(pendingReports.length);
      setCompleteCount(completeReports.length);
    }
  }, [jsonData,,from_date, to_date]);

  const downloadExcel = () => {
    if (!excelData.length) {
      console.error("No data to download");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(excelData);

    // Add counts in new cells
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Pending Reports", pendingCount.toString()]],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Complete Reports", completeCount.toString()]],
      { origin: -1 }
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "your_data.xlsx");
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },

      children: `${name.split(" ")[0][0]}`,
    };
  }

  return (
    <div>
      <div>
        <br />
        <lable>From:</lable>{" "}
        <input
          style={{
            height: "40px",
            border: "1px solid black",
            backgroundColor: "#EAEEF3",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          type="date"
          value={from_date}
          onChange={(e) => setfrom_date(e.target.value)}
        />
        &ensp; To:{" "}
        <input
          type="date"
          value={to_date}
          style={{
            height: "40px",
            border: "1px solid black",
            backgroundColor: "#EAEEF3",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          onChange={(e) => setto_date(e.target.value)}
        />{" "}
        &ensp;
        <Button
          style={{ width: "4%", height: "43px" }}
          onClick={() => {
            OnFilter(from_date, to_date);
          }}
        >
          <FaSearch />
        </Button>{" "}
        &ensp;
        <Button
          style={{ width: "4%", height: "43px" }}
          onClick={() => {
            setfrom_date("");
            setto_date("");
            OnRefresh();
          }}
        >
          <HiOutlineRefresh size={20} />
        </Button>
        <button
          style={{
            width: "10%",
            float: "right",
            backgroundColor: "#60C5F1",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={downloadExcel}
          disabled={!excelData.length}
        >
          Export
        </button>
      </div>
      {Loading ? (
        <div>Loading....</div>
      ) : (
        <table hover size="sm" style={{ textAlign: "center" }} className="mb-4">
          <tr
            style={{
              backgroundColor: "#DEE2E6",
              height: "35px",
              color: "#B5B5C3",
            }}
          >
            <th style={{ width: "1%" }}></th>
            <th>Patient Id</th>
            <th>Patient Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Requested Date</th>
            <th style={{ width: "10%" }}>Status</th>
          </tr>

          {data && data.length > 0 ? (
            data?.map((item, i) => (
              <tr key={i} style={{ height: "35px" }}>
                <td style={{ paddingLeft: "10px" }}>
                  <Avatar {...stringAvatar(item.PatientName)} />
                </td>
                <td>{item.PatientId}</td>
                <td>{item.PatientName}</td>
                <td>{item.Age}</td>
                <td>{item.Gender}</td>
                <td>{formatDate(item.SentReportDateTime)}</td>
                <td>
                  <div style={{ marginLeft: "25%" }}>
                    {item.Status === "Complete" ? (
                      <div
                        style={{
                          backgroundColor: "#AED4A8",
                          borderRadius: "5px",
                          width: "80px",
                          textAlign: "center",
                          fontSize: "16px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        {item.Status}{" "}
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#E49696",
                          borderRadius: "5px",
                          width: "80px",
                          textAlign: "center",
                          fontSize: "16px",
                        }}
                      >
                        {item.Status}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          )}
        </table>
      )}
      <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={Math.ceil(TotalCount / limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handleTotalPageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default TotalTable;
