import React from 'react'
import { Modal } from 'react-bootstrap';
import './modal.css';
import { IoMdCloseCircleOutline } from "react-icons/io";

const ModalBox = ({ show, handleClose, message, Style, close }) => {
  return (

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      style={Style}
      
    >
      {
        close ?
          <Modal.Title>
            <button style={{ float: "right", width: "50px",  borderRadius: "5px" }} onClick={handleClose}>
              <IoMdCloseCircleOutline />

            </button>
          </Modal.Title>
          : null
        }

      <Modal.Header style={{width:""}}>
        {message}
      </Modal.Header>
    </Modal>

  )
}

export default ModalBox
