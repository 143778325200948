import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row } from "react-bootstrap";

const ReportDiagosis = ({ fetchReportDiagosisData }) => {
  const org = localStorage.getItem("orgdata");
  let Review = JSON.parse(org);
  let ReviewerName = Review.ReviewerName;
  let ReviewerSignature = Review.ReviewerSignature;
  const [LeftEyeDiagnosis, setLeftEyeDiagnosis] = useState("");
  const [RightEyeDiagnosis, setRightEyeDiagnosis] = useState("");
  const [Recommendations, setRecommendations] = useState("");

  const [isEditable, setIsEditable] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsEditable(false); // Clicked outside the division, make non-editable
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInsideClick = () => {
    setIsEditable(true); // Clicked inside the division, make editable
  };

  const handleRightEyeDiagnosisChange = (e) => {
    setRightEyeDiagnosis(e.target.value);
    // fetchReportDiagosisData(LeftEyeDiagnosis, e.target.value, Recommendations);
  };

  const handleLeftEyeDiagnosisChange = (e) => {
    setLeftEyeDiagnosis(e.target.value);
    // fetchReportDiagosisData(e.target.value, RightEyeDiagnosis, Recommendations);
  };

  const handleRecommendationsChange = (e) => {
    setRecommendations(e.target.value);
    // fetchReportDiagosisData(
    //   LeftEyeDiagnosis,
    //   RightEyeDiagnosis,
    //   e.target.value
    // );
  };
  const labelStyle = {
    width: "100%",
    padding: "6px 12px",
    borderRadius: "4px",
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    textAlign: "left",
  };
    fetchReportDiagosisData(LeftEyeDiagnosis, RightEyeDiagnosis, Recommendations);

  return (
    <div>
      <div ref={containerRef} onClick={handleInsideClick}>
        <Row>
          {/* <h5 style={{ textAlign: "left", marginLeft: "3.8%" }}>Findings:</h5><br /><br /> */}
          <Col md={5} style={{ marginLeft: "3.8%" }} className="mb-4">
            <TextField
              id="standard-textarea"
              label="Right Eye Diagnosis"
              // placeholder="Placeholder"
              value={RightEyeDiagnosis}
              onChange={(e) => setRightEyeDiagnosis(e.target.value)}
              multiline
              variant="standard"
              style={{ width: "100%" }}
            />
            {/* {isEditable ? (
              <TextField
                id="standard-textarea-right-eye"
                label="Right Eye Diagnosis"
                value={RightEyeDiagnosis}
                onChange={handleRightEyeDiagnosisChange}
                multiline
                variant="standard"
                style={{ width: "100%" }}
              />
            ) : (
              null
              // <label style={labelStyle}>{RightEyeDiagnosis? <div>{RightEyeDiagnosis}</div> : <lable>Right Eye Diagnosis</lable>}</label>
            )} */}
          </Col>
          <Col md={5} style={{ marginLeft: "3.8%" }} className="mb-4">
            <TextField
              id="standard-textarea"
              label="Left Eye Diagnosis"
              // placeholder="Placeholder"
              value={LeftEyeDiagnosis}
              onChange={(e) => setLeftEyeDiagnosis(e.target.value)}
              multiline
              variant="standard"
              style={{ width: "100%" }}
            />
            {/* {isEditable ? (
              <TextField
                id="standard-textarea-left-eye"
                label="Left Eye Diagnosis"
                value={LeftEyeDiagnosis}
                onChange={handleLeftEyeDiagnosisChange}
                multiline
                variant="standard"
                style={{ width: "100%" }}
              />
            ) : (
              <label style={labelStyle}>{LeftEyeDiagnosis? <div>{LeftEyeDiagnosis}</div> : <lable>Left Eye Diagnosis</lable>}</label>
            )} */}
          </Col>
        </Row>

        <Row>
          <Col xs={11} style={{ marginLeft: "3.8%" }} className="mb-3">
            <TextField
              id="outlined-textarea"
              label="Recommendations"
              placeholder="Placeholder"
              value={Recommendations}
              onChange={(e) => setRecommendations(e.target.value)}
              multiline
              style={{ width: "100%" }}
            />
            {/* {isEditable ? (
              <TextField
                id="outlined-textarea-recommendations"
                label="Recommendations"
                placeholder="Placeholder"
                value={Recommendations}
                onChange={handleRecommendationsChange}
                multiline
                style={{ width: "100%" }}
              />
            ) : (
              <label style={labelStyle}>{Recommendations? <div>{Recommendations}</div> : <lable>Recommendations</lable>}</label>
            )} */}
          </Col>
        </Row>
      </div>

      <Row style={{ position: "relative", padding: "12px", height: "auto" }}>
        <Col md={2} style={{ height: "100%" }}></Col>
        <Col md={7}></Col>
        <Col md={3} style={{ height: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                height: "80px",
                width: "150px",
                backgroundColor: "red",
                margin: "auto",
              }}
            >
              {ReviewerSignature ? (
                <img
                  src={`data:image/jpeg;base64,${ReviewerSignature}`}
                  alt="Selected File"
                  width={"100%"}
                  height={"100%"}
                />
              ) : null}
            </div>
            {ReviewerName}
            <br />
            Signature
          </div>
        </Col>
      </Row>
      <hr style={{ backgroundColor: "#3498db", height: "1px" }} />
      <Row style={{ position: "relative", padding: "12px", height: "100px" }}>
        <Col md={12} style={{ height: "100%" }}>
          <div style={{ textAlign: "left", fontSize: "14px", lineHeight: "2" }}>
            <p>
              <b>Disclaimer: </b>This telemedicine report provides preliminary
              results and is not intended to constitute confirmation of the
              absence of vision problems. It is essential to consult with an
              Ophthalmologist for a comprehensive assessment and confirmation of
              any visual issues.
              <br />
              Powered by{" "}
              <b style={{ color: "#050081" }}>Right Medical Devices Pvt Ltd</b>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ReportDiagosis;
