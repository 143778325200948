import React from 'react'
import { Col, Row } from 'react-bootstrap'

const History = ({data, handleClose}) => {
    console.log(data)
    
  return (
    <div style={{width:"100%"}}>
      <h2 style={{textAlign:"center"}}>History</h2>
      {
        data? <div><h5>
        DiseaseHistory
        </h5>
        <table style={{width:"100%", borderCollapse: "collapse", textAlign:"center"}}>
            <tr>
                <th style={{ width: "20%", border: "1px solid" }}>Disease</th>
                <th style={{ width: "20%", border: "1px solid" }}>Present</th>
                <th style={{ width: "20%", border: "1px solid" }}>Years</th>
                <th style={{ border: "1px solid"}}>Details</th>
            </tr>
            <tr>
            <th style={{ border: "1px solid"}}>Asthma</th>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Asthma.IsPresent? <div>Yes</div>: <div>No</div>}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Asthma.Years}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Asthma.Details}</td>
            </tr>
            <tr>
            <th style={{ border: "1px solid"}}>DM</th>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.DM.IsPresent? <div>Yes</div>: <div>No</div>}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.DM.Years}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.DM.Details}</td>
            </tr>
            <tr>
            <th style={{ border: "1px solid"}}>HTN</th>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.HTN.IsPresent? <div>Yes</div>: <div>No</div>}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.HTN.Years}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.HTN.Details}</td>
            </tr>
            <tr>
            <th style={{ border: "1px solid"}}>Others</th>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Others.IsPresent? <div>Yes</div>: <div>No</div>}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Others.Years}</td>
            <td style={{ border: "1px solid"}}>{data.DiseaseHistory.Others.Details}</td>
            </tr>
        </table>
        <br/> 
        <Row>
            <Col md={6}>
                <h5>FamilyHistory :</h5>
                <label>{data.FamilyHistory}</label>
                
            </Col>
            <Col md={6}>
                <h5>MajorComplaints :</h5>
                <label>{data.MajorComplaints}</label>
            </Col>
        </Row> </div>: <h1><center>History not found</center></h1>
      }
        {/*  */}
      <button onClick={handleClose} style={{width:"15%", float: "right", borderRadius:"5px"}}>Close</button>
    </div>
  )
}

export default History
